import Button from "@mui/material/Button";
import { LoadingButton } from "@mui/lab";
import TextField from "@mui/material/TextField";
import { useParams, Link, useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Alert from "@mui/material/Alert";
import { useResetPasswordMutation } from "./authApiSlice";
import { ReactNode, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { FormGroup, FormHelperText } from "@mui/material";

const ResetPasswordPage: React.FC = () => {
  const [resetPassword, resetPasswordResult] = useResetPasswordMutation();
  const authTokens = useAppSelector((state) => state.auth.authTokens);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const uid = useParams().uid;
  const token = useParams().token;

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const email = data.get("email") as string;
    await resetPassword({ email: email });
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography component="h1" variant="h5">
          Reset password
        </Typography>
        <Box
          component="form"
          onSubmit={handleSubmit}
          noValidate
          sx={{ mt: 1, width: "100%" }}
        >
          <TextField
            error={resetPasswordResult.isError}
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
          />
          {resetPasswordResult.isSuccess && (
            <Alert severity="success">Password reset link sent!</Alert>
          )}
          <FormGroup>
            <FormHelperText error={resetPasswordResult.isError}>
              {resetPasswordResult.isError ? "No active account found" : ""}
            </FormHelperText>
          </FormGroup>
          <LoadingButton
            loading={resetPasswordResult.isLoading}
            variant="contained"
            type="submit"
            fullWidth
            sx={{ mt: 3, mb: 2 }}
          >
            Get password reset link
          </LoadingButton>
        </Box>
      </Box>
    </Container>
  );
};

export default ResetPasswordPage;
