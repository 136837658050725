import React from "react";
import { LoadingButton } from "@mui/lab";
import Button from "@mui/material/Button";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { logout } from "../authentication/authSlice";
import { useNavigate } from "react-router-dom";
import { useGetUserProfileQuery } from "./userProfileApiSlice";
import BillingTableComponent from "./billingTableComponent";

// function capitalizeFirstLetter(word: string) {
//   return word.charAt(0).toUpperCase() + word.slice(1);
// }

const UsagePage = () => {
  // const dispatch = useAppDispatch();
  // const navigate = useNavigate();
  // const user = useAppSelector((state) => state.auth.user);

  return (
    <div>
      <div style={{ fontSize: 20, margin: "30px" }}>Usage</div>
      {<BillingTableComponent />}
    </div>
  );
};

export default UsagePage;
