import React from "react";

import { useParams, Link } from "react-router-dom";
import { useGetAssignmentsQuery } from "./assignmentsApiSlice";
import AssignmentsList from "./assignmentsList";
import { useAppSelector } from "../../../redux/hooks";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import HomeIcon from "@mui/icons-material/Home";
import { CircularProgress } from "@mui/material";

const AssignmentsPage: React.FC = () => {
  const workingLms = useAppSelector((state) => state.lms.workingLms);
  const lmsID = useParams().lmsId;
  const courseID = useParams().courseID;
  const {
    data: assignmentsData = [],
    error,
    isLoading,
    isFetching,
    isSuccess,
  } = useGetAssignmentsQuery({
    lms_type: workingLms && workingLms.lmsType!,
    base_url: workingLms && workingLms.lmsBaseUrl!,
    access_token: workingLms && workingLms.lmsAccessToken!,
    courseId: +courseID!,
  });
  // console.log(assignmentsData);

  return (
    <div>
      <div className="grey-title-container">
        <Breadcrumbs aria-label="breadcrumb">
          <Link
            className="link"
            style={{ display: "flex", alignItems: "center" }}
            color="inherit"
            to="/"
          >
            <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
            Home
          </Link>
          <Link
            className="link"
            style={{ display: "flex", alignItems: "center" }}
            color="inherit"
            to={`/workspaces`}
          >
            Workspaces
          </Link>
          <Link
            className="link"
            style={{ display: "flex", alignItems: "center" }}
            color="inherit"
            to={`/workspaces/${lmsID}/courses`}
          >
            Courses
          </Link>
          {/* <Link
            className="link"
            style={{ display: "flex", alignItems: "center" }}
            color="inherit"
            to={`/workspaces/${lmsID}/courses/${courseID}`}
          >
            Assignments
          </Link> */}
          <div>Assignments</div>
        </Breadcrumbs>
      </div>
      <div className="grey-bordered-container">
        {isLoading ? (
          <CircularProgress />
        ) : (
          <AssignmentsList AssignmentsList={assignmentsData} />
        )}
      </div>
    </div>
  );
};

export default AssignmentsPage;
