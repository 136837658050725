import React from "react";
import { Avatar, List } from "antd";
import course_icon from "../assets/course_icon.jpg";
import { useParams, Link } from "react-router-dom";
import { Card, Spin, Button } from "antd";
import { useGetAssignmentQuestionsQuery } from "./questionsApiSlice";
import QuestionsList from "./questionsList";
import { LoadingOutlined } from "@ant-design/icons";
import { useAppSelector } from "../../../redux/hooks";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import HomeIcon from "@mui/icons-material/Home";

const QuestionsPage: React.FC = () => {
  const workingLms = useAppSelector((state) => state.lms.workingLms);
  const lmsID = useParams().lmsId;
  const courseID = useParams().courseID;
  const assignmentID = useParams().assignmentID;
  const {
    data: questionsData = {},
    error,
    isLoading,
    isFetching,
    isSuccess,
  } = useGetAssignmentQuestionsQuery({
    lms_type: workingLms && workingLms.lmsType!,
    base_url: workingLms && workingLms.lmsBaseUrl!,
    access_token: workingLms && workingLms.lmsAccessToken!,
    courseId: +courseID!,
    assignmentId: +assignmentID!,
  });
  // console.log(questionsData);

  const antIcon = <LoadingOutlined style={{ fontSize: 30 }} spin />;

  return (
    <div>
      {/* <div className="grey-title-container">
        <Breadcrumbs aria-label="breadcrumb">
          <Link
            className="link"
            style={{ display: "flex", alignItems: "center" }}
            color="inherit"
            to="/"
          >
            <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
            Home
          </Link>
          <Link
            className="link"
            style={{ display: "flex", alignItems: "center" }}
            color="inherit"
            to="/lms"
          >
            Courses
          </Link>
          <Link
            className="link"
            style={{ display: "flex", alignItems: "center" }}
            color="inherit"
            to={`/lms/courses/${courseID}`}
          >
            Assignments
          </Link>
          <Link
            className="link"
            style={{ display: "flex", alignItems: "center" }}
            color="inherit"
            to={`/lms/courses/${courseID}/assignments/${assignmentID}`}
          >
            Questions
          </Link>
        </Breadcrumbs>
      </div> */}
      <div className="grey-bordered-container">
        {isLoading ? (
          <Spin indicator={antIcon} />
        ) : (
          <QuestionsList QuestionsList={Object.values(questionsData)} />
        )}
      </div>
    </div>
  );
};

export default QuestionsPage;
