import React, { useEffect } from "react";
// import { Avatar, List, Card, Spin, Button } from "antd";
import { useParams, Link } from "react-router-dom";
import QuestionItem from "./questionItem";
import { Question } from "../lmsModels";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import { useGetAssignmentGradingStatusQuery } from "../../lmsManagement/lmsManagementApiSlice";

interface QuestionsListProps {
  QuestionsList: Question[];
}

const QuestionsList: React.FC<QuestionsListProps> = ({ QuestionsList }) => {
  const lmsID = useParams().lmsId;
  const courseID = useParams().courseID;
  const assignmentID = useParams().assignmentID;

  const {
    data: statusData,
    error,
    isLoading,
    isFetching,
    isSuccess,
  } = useGetAssignmentGradingStatusQuery({
    lmsId: +lmsID!,
    courseLmsId: +courseID!,
    assignmentLmsId: +assignmentID!,
    questionLmsId: null,
  });

  // useEffect(() => {
  //   const statusInfo = statusData;
  //   console.log(statusInfo);
  // }, [isSuccess]);

  return (
    // <List
    //   size="small"
    //   itemLayout="horizontal"
    //   dataSource={QuestionsList}
    //   renderItem={(item, index) => (
    //     <QuestionItem question={item} index={index} />
    //   )}
    // />
    <List sx={{ width: "100%", bgcolor: "background.paper" }}>
      {QuestionsList.map((question, index) => (
        <React.Fragment key={index}>
          <ListItem
            alignItems="flex-start"
            sx={{ width: "100%" }}
            // component={Link}
            // to={`/lms/courses/${course.id}`}
          >
            {/* <ListItemAvatar>
            <Avatar alt={course.name} src="/static/images/avatar/1.jpg" />
          </ListItemAvatar> */}
            <ListItemText
              primary={
                <Link
                  className="link"
                  to={`/workspaces/${lmsID}/courses/${courseID}/assignments/${assignmentID}/questions/${question.id}`}
                  // style={{ fontWeight: "bold" }}
                >
                  {`${index + 1}. ${question.name} `}
                </Link>
              }
              secondary={
                <Typography
                  sx={{ display: "inline", color: "grey" }}
                  component="span"
                  variant="body2"
                  color="text.primary"
                >
                  {`Submissions graded: ${
                    statusData
                      ? statusData.questions[`${question.id}`]
                        ? statusData.questions[`${question.id}`]
                        : "0"
                      : ""
                  }`}
                </Typography>
              }
            />
          </ListItem>
          {index !== QuestionsList.length - 1 && <Divider />}
        </React.Fragment>
      ))}
    </List>
  );
};

export default QuestionsList;
