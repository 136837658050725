import { apiSlice } from "../api/apiSlice";
import {
  Lms,
  UserLms,
  Rubric,
  LatestQuestionSubmissions,
  LatestStudentQuestionsSubmissions,
} from "./lmsManagementModels";

export interface CreateUserLmsRequest {
  LmsId: string;
  AccessToken: string;
}

export interface UpdateUserLmsRequest {
  lmsId: number;
  userLmsId: number;
  AccessToken: string;
}

export interface UserLmsRequest {
  userLmsId: number;
}

export interface RubricListRequest {
  lmsId: number;
  courseLmsId: number;
  assignmentLmsId: number;
}

export interface RubricCreateUpdateRequest {
  lmsId: number;
  courseLmsId: number;
  assignmentLmsId: number;
  questionLmsId: number;
  rubric: string;
}

export interface GetLatestQuestionSubmissionsRequest {
  lmsId: number;
  courseLmsId: number;
  assignmentLmsId: number;
  questionLmsId: number;
}

export interface GetLatestStudentQuestionsSubmissionsRequest {
  lmsId: number;
  courseLmsId: number;
  assignmentLmsId: number;
  studentLmsId: number;
}

export interface GetUserUsageResponse {
  course_lms_id: number;
  assignment_lms_id: number;
  question_lms_id: number;
  num_graded_submissions: number;
  sum_tokens: number;
}

export interface GetAssignmentGradingStatusRequest {
  lmsId: number;
  courseLmsId: number;
  assignmentLmsId: number;
  questionLmsId: null | number;
}

export interface GetAssignmentGradingStatusResponse {
  questions: { [id: string]: number };
  students: { [id: string]: number };
}

export const extendedApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // --------------------------------------------------------------
    getLmses: builder.query<Lms[], void>({
      query: () => {
        return {
          url: "lms_management/lms_list/",
          method: "get",
        };
      },
    }),
    // --------------------------------------------------------------
    getUserLmses: builder.query<UserLms[], void>({
      query: () => {
        return {
          url: "lms_management/user_lms_list_create/",
          method: "get",
        };
      },
    }),
    // --------------------------------------------------------------
    getUserLms: builder.query<UserLms, UserLmsRequest>({
      query: (data) => {
        return {
          url: `lms_management/user_lms_detail/${data.userLmsId}/`,
          method: "get",
        };
      },
    }),
    // --------------------------------------------------------------
    createUserLms: builder.mutation<{}, CreateUserLmsRequest>({
      query: (data) => {
        return {
          url: "lms_management/user_lms_list_create/",
          method: "post",
          body: {
            lms: data.LmsId,
            access_token: data.AccessToken,
          },
        };
      },
    }),
    // --------------------------------------------------------------
    updateUserLms: builder.mutation<{}, UpdateUserLmsRequest>({
      query: (data) => {
        return {
          url: `lms_management/user_lms_detail/${data.userLmsId}/`,
          method: "put",
          body: {
            lms: data.lmsId,
            access_token: data.AccessToken,
          },
        };
      },
    }),
    // --------------------------------------------------------------
    deleteUserLms: builder.mutation<{}, UserLmsRequest>({
      query: (data) => {
        return {
          url: `lms_management/user_lms_detail/${data.userLmsId}/`,
          method: "delete",
        };
      },
    }),
    // --------------------------------------------------------------
    getLmsCourseAssignmentRubrics: builder.mutation<
      Rubric[],
      RubricListRequest
    >({
      query: (data) => {
        return {
          url: "lms_management/lms_rubric_retrieve/",
          method: "post",
          body: {
            lms: data.lmsId,
            course_lms_id: data.courseLmsId,
            assignment_lms_id: data.assignmentLmsId,
          },
        };
      },
    }),
    // --------------------------------------------------------------
    createUpdateLmsCourseAssignmentQuestionRubric: builder.mutation<
      {},
      RubricCreateUpdateRequest
    >({
      query: (data) => {
        return {
          url: "lms_management/lms_rubric_create_update/",
          method: "post",
          body: {
            lms: data.lmsId,
            course_lms_id: data.courseLmsId,
            assignment_lms_id: data.assignmentLmsId,
            question_lms_id: data.questionLmsId,
            rubric: data.rubric,
          },
        };
      },
    }),
    // --------------------------------------------------------------

    getLatestQuestionSubmissions: builder.query<
      LatestQuestionSubmissions,
      GetLatestQuestionSubmissionsRequest
    >({
      query: (data) => {
        return {
          url: "lms_management/lms_latest_question_submissions/",
          method: "post",
          body: {
            lms: data.lmsId,
            course_lms_id: data.courseLmsId,
            assignment_lms_id: data.assignmentLmsId,
            question_lms_id: data.questionLmsId,
          },
        };
      },
    }),

    // --------------------------------------------------------------
    getLatestStudentQuestionsSubmissions: builder.query<
      LatestStudentQuestionsSubmissions,
      GetLatestStudentQuestionsSubmissionsRequest
    >({
      query: (data) => {
        return {
          url: "lms_management/lms_latest_student_questions_submissions/",
          method: "post",
          body: {
            lms: data.lmsId,
            course_lms_id: data.courseLmsId,
            assignment_lms_id: data.assignmentLmsId,
            student_lms_id: data.studentLmsId,
          },
        };
      },
    }),

    // --------------------------------------------------------------
    getUserUsage: builder.query<GetUserUsageResponse[], void>({
      query: () => {
        return {
          url: "lms_management/get_user_usage/",
          method: "get",
        };
      },
    }),

    // --------------------------------------------------------------
    getAssignmentGradingStatus: builder.query<
      GetAssignmentGradingStatusResponse,
      GetAssignmentGradingStatusRequest
    >({
      query: (data) => {
        return {
          url: "lms_management/lms_assignment_grading_status/",
          method: "post",
          body: {
            lms: data.lmsId,
            course_lms_id: data.courseLmsId,
            assignment_lms_id: data.assignmentLmsId,
            question_lms_id: data.questionLmsId,
          },
        };
      },
    }),

    // --------------------------------------------------------------
  }),
});

export const {
  useGetLmsesQuery,
  useGetUserLmsesQuery,
  useGetUserLmsQuery,
  useCreateUserLmsMutation,
  useUpdateUserLmsMutation,
  useDeleteUserLmsMutation,
  useGetLmsCourseAssignmentRubricsMutation,
  useCreateUpdateLmsCourseAssignmentQuestionRubricMutation,
  useGetLatestQuestionSubmissionsQuery,
  useLazyGetLatestQuestionSubmissionsQuery,
  useGetLatestStudentQuestionsSubmissionsQuery,
  useGetUserUsageQuery,
  useGetAssignmentGradingStatusQuery,
} = extendedApiSlice;
