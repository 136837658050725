import React from "react";
import { Route, Routes } from "react-router-dom";
import CWL from "./components/cwlAuth";
import CoursesPage from "./features/lms/courses/coursesPage";
import AssignmentsPage from "./features/lms/assignments/assignmentsPage";
import AssignmentPage from "./features/lms/assignments/assignmentPage";
import CoursePage from "./features/lms/courses/coursePage";
import QuestionSubmissionsPage from "./features/lms/submissions/byQuestion/questionSubmissionsPage";
import StudentQuestionsSubmissionList from "./features/lms/submissions/byStudent/studentQuestionsSubmissionList";
import StudentQuestionsSubmissionsPage from "./features/lms/submissions/byStudent/studentQuestionsSubmissionPage";
import HomePage from "./components/homePage";
import PricingPage from "./components/pricingPage";
import LoginPage from "./features/authentication/loginPage";
import RegisterPage from "./features/authentication/registerPage";
import ProfilePage from "./features/userProfile/profilePage";
import AboutUsPage from "./components/aboutUsPage";
import AddLmsPage from "./features/lmsManagement/addLmsPage";
import ResetPasswordPage from "./features/authentication/resetPasswordPage";
import ResetPasswordConfirmPage from "./features/authentication/resetPasswordConfirmPage";
import UserActivationPage from "./features/authentication/userActivationPage";
import RegistrationConfirmationPage from "./features/authentication/registrationConfirmationPage";
import WorkspacesPage from "./features/lmsManagement/workspacesPage";
import PrivateRoutes from "./features/utils/PrivateRoutes";
import PageNotFound from "./features/utils/PageNotFound";
import LoggedInRoutes from "./features/utils/LoggedInRoutes";
import SettingsPage from "./features/userProfile/settingsPage";
import UsagePage from "./features/userProfile/usagePage";
import PlayGroundPage from "./features/userProfile/playGroundPage";

const BaseRouter = () => {
  return (
    <div>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/pricing" element={<PricingPage />} />
        <Route path="/about" element={<AboutUsPage />} />
        <Route element={<PrivateRoutes />}>
          <Route path="/workspaces" element={<WorkspacesPage />} />
          <Route path="/workspaces/:lmsId/courses" element={<CoursesPage />} />
          <Route
            path="/workspaces/:lmsId/courses/:courseID"
            element={<CoursePage />}
          />
          <Route
            path="/workspaces/:lmsId/courses/:courseID/assignments/:assignmentID"
            element={<AssignmentPage />}
          />
          <Route
            path="/workspaces/:lmsId/courses/:courseID/assignments/:assignmentID/students/:studentID"
            element={<StudentQuestionsSubmissionsPage />}
          />
          <Route
            path="/workspaces/:lmsId/courses/:courseID/assignments/:assignmentID/questions/:questionID"
            element={<QuestionSubmissionsPage />}
          />
          <Route path="/profile" element={<ProfilePage />} />
          <Route path="/playground" element={<PlayGroundPage />} />
          <Route path="/usage" element={<UsagePage />} />
          <Route path="/settings" element={<SettingsPage />} />
        </Route>
        <Route path="/register" element={<RegisterPage />} />
        <Route
          path="/registration-confirmation"
          element={<RegistrationConfirmationPage />}
        />
        <Route element={<LoggedInRoutes />}>
          <Route path="/login" element={<LoginPage />} />
        </Route>
        <Route path="/reset-password" element={<ResetPasswordPage />} />
        <Route
          path="/password/reset/confirm/:uid/:token"
          element={<ResetPasswordConfirmPage />}
        />
        <Route path="/activate/:uid/:token" element={<UserActivationPage />} />

        <Route path="/add-lms" element={<AddLmsPage />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </div>
  );
};

export default BaseRouter;
