import React from "react";
import { LoadingButton } from "@mui/lab";
import Button from "@mui/material/Button";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { logout } from "../authentication/authSlice";
import { useNavigate } from "react-router-dom";
import { useGetUserProfileQuery } from "./userProfileApiSlice";
import LMSList from "../../legacy/lmsListOld";
import { textAlign } from "@mui/system";
import EngineTokenDialog from "./engineTokenDialog";
import LMSListNew from "../lmsManagement/lmsList";

function capitalizeFirstLetter(word: string) {
  return word.charAt(0).toUpperCase() + word.slice(1);
}

const ProfilePage = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const user = useAppSelector((state) => state.auth.user);

  const {
    data: profileData,
    error,
    isLoading,
    isFetching,
    isSuccess,
  } = useGetUserProfileQuery();

  const onclick = () => {
    dispatch(logout());
    navigate("/");
  };

  return (
    <div>
      <div>
        {
          // isSuccess
          true ? (
            <p
              style={{
                textAlign: "center",
                fontSize: 30,
              }}
            >
              {/* {`Welcome ${capitalizeFirstLetter(profileData.first_name)}!`} */}
              {`Welcome ${capitalizeFirstLetter(user!.firstName)}!`}
            </p>
          ) : (
            ""
          )
        }
        {/* <LoadingButton
          style={{ maxWidth: 100, margin: "5px" }}
          loading={false}
          variant="contained"
          type="submit"
          fullWidth
          onClick={onclick}
          sx={{ mt: 3, mb: 2 }}
        >
          Logout
        </LoadingButton> */}
      </div>

      {/* <EngineTokenDialog /> */}

      {/* <p className="grey-title-container">My LMS list</p> */}
      {/* <div className="grey-bordered-container"> */}
      {/* <LMSList /> */}
      {/* <LMSListNew /> */}
      {/* </div> */}
    </div>
  );
};

export default ProfilePage;
