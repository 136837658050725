import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import StopIcon from "@mui/icons-material/Stop";
import { IconButton } from "@mui/material";
import {
  useTerminateGradingQuestionSubmissionsJobMutation,
  useUpdateGradingQuestionSubmissionsJobMutation,
} from "./graderApiSlice";

interface Props {
  job_id: number;
}

const StopJobDialog: React.FC<Props> = ({ job_id }) => {
  const dispatch = useAppDispatch();
  const [open, setOpen] = React.useState(false);

  // const [
  //   UpdateGradingQuestionSubmissionsJob,
  //   UpdateGradingQuestionSubmissionsJobResults,
  // ] = useUpdateGradingQuestionSubmissionsJobMutation();

  const [
    TerminateGradingQuestionSubmissionsJob,
    TerminateGradingQuestionSubmissionsJobResults,
  ] = useTerminateGradingQuestionSubmissionsJobMutation();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleStop = () => {
    console.log(job_id);
    TerminateGradingQuestionSubmissionsJob({ job_id: job_id })
      .unwrap()
      .then(() => {
        handleClose();
        window.location.reload();
      })
      .catch(() => {
        handleClose();
      });
  };

  //   React.useEffect(() => {}, []);

  return (
    <div>
      <Button
        sx={{ mb: "5px", textAlign: "center" }}
        color="error"
        variant="outlined"
        onClick={handleClickOpen}
        endIcon={<StopIcon sx={{ mr: 0.5 }} fontSize="inherit" />}
      >
        Stop
      </Button>
      <Dialog open={open} onClose={handleClose} maxWidth="md">
        <DialogTitle id="alert-dialog-title">
          {"Stop the Process?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to stop the process?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleStop} autoFocus color="error">
            Stop
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default StopJobDialog;
