import React from "react";
import { useParams, Link } from "react-router-dom";
import { useGetAssignmentStudentsQuery } from "./studentsApiSlice";
import StudentsList from "./studentsList";
import { LoadingOutlined } from "@ant-design/icons";
import { useAppSelector } from "../../../redux/hooks";
import CircularProgress from "@mui/material/CircularProgress";

const StudentsPage: React.FC = () => {
  const workingLms = useAppSelector((state) => state.lms.workingLms);
  const lmsID = useParams().lmsId;
  const courseID = useParams().courseID;
  const assignmentID = useParams().assignmentID;
  const {
    data: studentsData = [],
    error,
    isLoading,
    isFetching,
    isSuccess,
  } = useGetAssignmentStudentsQuery({
    lms_type: workingLms && workingLms.lmsType!,
    base_url: workingLms && workingLms.lmsBaseUrl!,
    access_token: workingLms && workingLms.lmsAccessToken!,
    courseId: +courseID!,
    assignmentId: +assignmentID!,
  });
  //   console.log(studentsData);

  const antIcon = <LoadingOutlined style={{ fontSize: 30 }} spin />;

  return (
    <div>
      <div className="grey-bordered-container">
        {isLoading ? (
          <CircularProgress />
        ) : (
          <StudentsList StudentsList={studentsData} />
        )}
      </div>
    </div>
  );
};

export default StudentsPage;
