import React from "react";
import Button from "@mui/material/Button";
import { LoadingButton } from "@mui/lab";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import { Autocomplete, FormGroup, FormHelperText } from "@mui/material";
import {
  useCreateUserLmsMutation,
  useGetLmsesQuery,
} from "./lmsManagementApiSlice";
import { useNavigate } from "react-router-dom";
import { ReactNode, useEffect } from "react";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

const AddLmsPage: React.FC = () => {
  const [CreateUserLms, CreateUserLmsResults] = useCreateUserLmsMutation();
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const { data, refetch, error, isLoading, isFetching, isSuccess } =
    useGetLmsesQuery();

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const lms = data.get("lms") as string;
    const access_token = data.get("access_token") as string;
    console.log(lms, access_token);
    await CreateUserLms({
      LmsId: lms,
      AccessToken: access_token,
    });
  };

  useEffect(() => {
    if (CreateUserLmsResults.data) {
      if (CreateUserLmsResults.isSuccess) {
        navigate("/workspaces");
        // navigate(0);
        // navigate("/profile", { replace: true });
        // window.location.reload();
      }
    }
  }, [CreateUserLmsResults.isSuccess]);

  const getErrorMessage = (error: any, field: string): [boolean, string] => {
    if (error && error.data && error.data[`${field}`]) {
      return [true, `${error.data[`${field}`]}`];
    }
    return [false, ""];
  };

  const lms_types = [
    {
      value: "canvas",
      label: "Canvas",
    },
  ];

  return (
    <div>
      {data && (
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            error={getErrorMessage(CreateUserLmsResults.error, "lms")[0]}
            helperText={getErrorMessage(CreateUserLmsResults.error, "lms")[1]}
            margin="normal"
            required
            select
            defaultValue=""
            fullWidth
            label="LMS"
            id="lms"
            name="lms"
            size="small"
          >
            {data.map((lms) => (
              <MenuItem key={lms.base_url} value={lms.id}>
                {`${lms.type}: ${lms.institution}`}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            error={
              getErrorMessage(CreateUserLmsResults.error, "access_token")[0]
            }
            helperText={
              getErrorMessage(CreateUserLmsResults.error, "access_token")[1]
            }
            margin="normal"
            required
            fullWidth
            label="Access Token"
            id="access_token"
            name="access_token"
            size="small"
            autoComplete="new-password"
            type={showPassword ? "text" : "password"}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          <FormGroup>
            <FormHelperText error={CreateUserLmsResults.isError}>
              {getErrorMessage(
                CreateUserLmsResults.error,
                "non_field_errors"
              )[0]
                ? "The given LMS configuration already exists"
                : ""}
            </FormHelperText>
          </FormGroup>
          <LoadingButton
            loading={CreateUserLmsResults.isLoading}
            variant="contained"
            type="submit"
            fullWidth
            sx={{ mt: 3, mb: 2 }}
          >
            Add LMS
          </LoadingButton>
        </Box>
      )}
    </div>
  );
};

export default AddLmsPage;
