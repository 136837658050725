import React, { useState, useEffect } from "react";
import { Input } from "antd";
import { useParams, Link } from "react-router-dom";
import { Question, QuestionSubmission } from "../../lmsModels";
import { LoadingButton } from "@mui/lab";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { addGraderResult } from "../../../grader/graderSlice";
import { useGetSubmissionGradingResultMutation } from "../../../grader/graderApiSlice";
import { addLmsRubric } from "../../lmsSlice";
import {
  useCreateUpdateLmsCourseAssignmentQuestionRubricMutation,
  useGetLatestQuestionSubmissionsQuery,
  useGetLatestStudentQuestionsSubmissionsQuery,
  useGetLmsCourseAssignmentRubricsMutation,
} from "../../../lmsManagement/lmsManagementApiSlice";
import {
  Box,
  Card,
  CardHeader,
  Divider,
  Grid,
  ListItem,
  Typography,
} from "@mui/material";
import DOMPurify from "dompurify";
import formattedDateTime from "../../../utils/utilFunctions";
interface StudentQuestionSubmissionItemProps {
  StudentQuestionsSubmission: QuestionSubmission;
  index: number;
}

const StudentQuestionSubmissionItem: React.FC<
  StudentQuestionSubmissionItemProps
> = ({ StudentQuestionsSubmission, index }) => {
  const dispatch = useAppDispatch();

  const { TextArea } = Input;

  const [feedback, setFeedback] = useState("");
  const [grade, setGrade] = useState(0);
  const [createdAt, setCreatedAt] = useState("");

  const graderResults = useAppSelector((state) => state.grader.graderResults);
  const engineToken = useAppSelector((state) => state.lms.engineToken);
  const rubrics = useAppSelector((state) => state.lms.lmsRubrics);
  const workingLms = useAppSelector((state) => state.lms.workingLms);
  const courseID = useParams().courseID;
  const assignmentID = useParams().assignmentID;

  const [gradeSubmission, SubmissionGradingResult] =
    useGetSubmissionGradingResultMutation();

  const [GetLmsCourseAssignmentRubrics, GetLmsCourseAssignmentRubricsResults] =
    useGetLmsCourseAssignmentRubricsMutation();

  const [
    CreateUpdateLmsCourseAssignmentQuestionRubric,
    CreateUpdateLmsCourseAssignmentQuestionRubricResults,
  ] = useCreateUpdateLmsCourseAssignmentQuestionRubricMutation();

  const onGradeClick = async () => {
    await gradeSubmission({
      engine_token: engineToken!,
      lms_id: workingLms && workingLms.lmsId!,
      course_lms_id: +courseID!,
      assignment_lms_id: +assignmentID!,
      question_lms_id: StudentQuestionsSubmission.question.id,
      student_lms_id: StudentQuestionsSubmission.student.id,
      submission_lms_id: StudentQuestionsSubmission.id,
      question_text: StudentQuestionsSubmission.question.question_text,
      solution: StudentQuestionsSubmission.question.solution,
      answer: StudentQuestionsSubmission.answer,
      rubric: rubrics[StudentQuestionsSubmission.question.id]
        ? rubrics[StudentQuestionsSubmission.question.id]
        : "",
      points_possible: StudentQuestionsSubmission.question.points_possible,
    });
  };

  const questionGradedResult = () => {
    let feedback;
    let grade;
    if (StudentQuestionsSubmission.question.id in graderResults) {
      if (
        StudentQuestionsSubmission.student.id in
        graderResults[StudentQuestionsSubmission.question.id]
      ) {
        feedback =
          graderResults[StudentQuestionsSubmission.question.id][
            StudentQuestionsSubmission.student.id
          ].result.feedback;
        grade =
          graderResults[StudentQuestionsSubmission.question.id][
            StudentQuestionsSubmission.student.id
          ].result.grade;
        setFeedback(feedback);
        setGrade(grade);
      }
    } else {
      feedback = "";
      grade = 0;
    }
  };

  useEffect(() => {
    if (SubmissionGradingResult.isSuccess) {
      const result = SubmissionGradingResult.data
        ? {
            grade: SubmissionGradingResult.data.result.grade!,
            feedback: SubmissionGradingResult.data.result.feedback!,
            created_at: SubmissionGradingResult.data.result.created_at!,
          }
        : { grade: 0, feedback: "", created_at: "" };
      dispatch(
        addGraderResult({
          question_id: StudentQuestionsSubmission.question.id,
          student_id: StudentQuestionsSubmission.student.id,
          result: {
            result: result,
          },
        })
      );
      setFeedback(result.feedback);
      setGrade(result.grade);
      setCreatedAt(result.created_at);
    }
    // setOutput(questionGradedResult()!);
    // console.log(output);
  }, [SubmissionGradingResult.isSuccess]);

  useEffect(() => {
    questionGradedResult();
  });

  const onRubricChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    // console.log(e);
    dispatch(
      addLmsRubric({
        question_id: StudentQuestionsSubmission.question.id,
        rubric: e.target.value,
      })
    );
  };

  const {
    data: StudentQuestionsSubmissionsData,
    error: StudentQuestionsSubmissionsEror,
    isLoading: StudentQuestionsSubmissionsIsLoading,
    isFetching: StudentQuestionsSubmissionsIsFetching,
    isSuccess: StudentQuestionsSubmissionsIsSuccess,
  } = useGetLatestStudentQuestionsSubmissionsQuery({
    lmsId: workingLms && workingLms.lmsId!,
    courseLmsId: +courseID!,
    assignmentLmsId: +assignmentID!,
    studentLmsId: StudentQuestionsSubmission.student.id,
  });

  useEffect(() => {
    if (StudentQuestionsSubmissionsData) {
      const feedback =
        StudentQuestionsSubmissionsData[
          String(StudentQuestionsSubmission.question.id)
        ] &&
        StudentQuestionsSubmissionsData[
          String(StudentQuestionsSubmission.question.id)
        ].feedback;
      setFeedback(feedback);
      const grade =
        StudentQuestionsSubmissionsData[
          String(StudentQuestionsSubmission.question.id)
        ] &&
        StudentQuestionsSubmissionsData[
          String(StudentQuestionsSubmission.question.id)
        ].grade;
      setGrade(grade);
      const created_at =
        StudentQuestionsSubmissionsData[
          String(StudentQuestionsSubmission.question.id)
        ] &&
        StudentQuestionsSubmissionsData[
          String(StudentQuestionsSubmission.question.id)
        ].created_at;
      setCreatedAt(created_at);
    }
  }, [StudentQuestionsSubmissionsIsSuccess]);

  useEffect(() => {
    // fetch the assignment rubrics on mount
    GetLmsCourseAssignmentRubrics({
      lmsId: workingLms && workingLms.lmsId!,
      courseLmsId: +courseID!,
      assignmentLmsId: +assignmentID!,
    });
  }, []);

  useEffect(() => {
    // set the rubric state for that question when fetch is complete
    dispatch(
      addLmsRubric({
        question_id: StudentQuestionsSubmission.question.id,
        rubric: evaluateValue(StudentQuestionsSubmission.question.id),
      })
    );
  }, [GetLmsCourseAssignmentRubricsResults]);

  function evaluateValue(questionId: number) {
    if (GetLmsCourseAssignmentRubricsResults.data) {
      const questionRubric = GetLmsCourseAssignmentRubricsResults.data!.find(
        (obj) => obj.question_lms_id === questionId
      );
      if (questionRubric) {
        // console.log(questionRubric.rubric);
        return questionRubric.rubric;
      }
    }
    return rubrics[StudentQuestionsSubmission.question.id];
  }

  const saveRubric = () => {
    CreateUpdateLmsCourseAssignmentQuestionRubric({
      lmsId: workingLms && workingLms.lmsId!,
      courseLmsId: +courseID!,
      assignmentLmsId: +assignmentID!,
      questionLmsId: StudentQuestionsSubmission.question.id,
      rubric: rubrics[StudentQuestionsSubmission.question.id],
    });
  };

  return GetLmsCourseAssignmentRubricsResults.data ? (
    <Grid container>
      <ListItem
        sx={{
          width: "100%",
          paddingX: "15px",
        }}
      >
        <Card
          raised={true}
          sx={{
            width: "100%",
            backgroundColor: "#f5f5f5",
            border: "1px solid lightgrey",
            borderRadius: "10px",
          }}
        >
          <CardHeader
            sx={{ backgroundColor: "white" }}
            title={
              <Typography sx={{ fontWeight: "bold" }}>
                {`${index + 1}. ${StudentQuestionsSubmission.question.name}`}
              </Typography>
            }
            // subheader={`ID: ${StudentQuestionsSubmission.question.id}`}
          />

          <div className="grey-bordered-submission-container">
            <Box>
              <p style={{ margin: "5px", fontSize: 15, fontWeight: "bold" }}>
                {"Question:"}
              </p>
              <div
                className="white-bordred-container"
                style={{
                  fontSize: 15,
                  overflow: "auto",
                }}
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(
                    StudentQuestionsSubmission.question.question_text
                  ),
                }}
              ></div>
              <p style={{ margin: "5px", fontSize: 15, fontWeight: "bold" }}>
                {"Solution:"}
              </p>
              <div
                className="white-bordred-container"
                style={{
                  fontSize: 15,
                  overflow: "auto",
                }}
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(
                    StudentQuestionsSubmission.question.solution
                  ),
                }}
              ></div>

              <p style={{ margin: "5px", fontSize: 15, fontWeight: "bold" }}>
                {"Rubric:"}
              </p>
              <div
                style={{
                  marginTop: "0px",
                  marginBottom: "20px",
                  padding: "10px",
                }}
              >
                <TextArea
                  showCount
                  maxLength={500}
                  style={{ height: 120, resize: "none" }}
                  placeholder="Provide a rubric to enforce grading criteria "
                  defaultValue={evaluateValue(
                    StudentQuestionsSubmission.question.id
                  )}
                  onChange={onRubricChange}
                ></TextArea>
                <LoadingButton
                  style={{ marginTop: "5px" }}
                  loading={false}
                  variant="contained"
                  onClick={saveRubric}
                >
                  Save Rubric
                </LoadingButton>
              </div>

              <p style={{ margin: "5px", fontSize: 15, fontWeight: "bold" }}>
                {"Answer:"}
              </p>
              <div
                className="white-bordred-container"
                style={{
                  fontSize: 15,
                  overflow: "auto",
                }}
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(StudentQuestionsSubmission.answer),
                }}
              ></div>

              <p style={{ margin: "5px", fontSize: 15, fontWeight: "bold" }}>
                {"Submitted Feedback:"}
              </p>
              {/* <TextArea
                showCount
                maxLength={1000}
                style={{
                  fontSize: 15,
                  margin: "5px",
                  height: 80,
                  resize: "none",
                }}
                placeholder="Submitted Feedback "
                defaultValue={StudentQuestionsSubmission.comments}
              ></TextArea> */}
              <div
                className="white-bordred-container"
                style={{ overflowWrap: "break-word", fontSize: 15 }}
              >
                {StudentQuestionsSubmission.comments}
              </div>
              <p
                style={{ margin: "5px", fontSize: 15, fontWeight: "bold" }}
              >{`Submitted Grade: ${StudentQuestionsSubmission.points}/ ${StudentQuestionsSubmission.question.points_possible}`}</p>
              <Divider />
              <p style={{ margin: "5px", fontSize: 15, fontWeight: "bold" }}>
                {"AI Feedback:"}
              </p>
              <div
                className="white-bordred-container"
                style={{ overflowWrap: "break-word", fontSize: 15 }}
              >
                {feedback}
              </div>
              <p
                style={{ margin: "5px", fontSize: 15, fontWeight: "bold" }}
              >{`AI Grade: ${grade ? grade : "-"}/ ${
                StudentQuestionsSubmission.question.points_possible
              }`}</p>
              <LoadingButton
                style={{ margin: "5px" }}
                loading={SubmissionGradingResult.isLoading}
                variant="contained"
                onClick={onGradeClick}
              >
                Grade
              </LoadingButton>
              {createdAt && (
                <p
                  style={{
                    margin: "0px",
                    color: "grey",
                    textAlign: "center",
                    fontSize: "0.8rem",
                  }}
                >
                  Last graded at: {formattedDateTime(createdAt)}
                </p>
              )}
            </Box>
          </div>
        </Card>
      </ListItem>
    </Grid>
  ) : (
    <div></div>
  );
};

export default StudentQuestionSubmissionItem;
