import { createSelector, createEntityAdapter } from "@reduxjs/toolkit";
import { apiSlice } from "../api/apiSlice";
import { ActiveJobResult, GraderResult } from "./graderModels";

export interface GetSubmissionGraderRequest {
  engine_token?: string;
  lms_id?: number;
  course_lms_id?: number;
  assignment_lms_id?: number;
  question_lms_id?: number;
  student_lms_id?: number;
  submission_lms_id?: number;
  question_text: string;
  solution: string;
  answer: string;
  rubric: string;
  points_possible: number;
}

export interface GradeAllQuestionSubmissionsRequest {
  engine_token: string;
  lms_id: number;
  user_lms_id: number;
  course_lms_id: number;
  assignment_lms_id: number;
  question_lms_id: number;
  top_n_submissions: number | null;
  skip_graded_submissions: boolean;
  rubric: string;
}

export interface SubmitAllQuestionSubmissionsRequest {
  lms_id: number;
  user_lms_id: number;
  course_lms_id: number;
  assignment_lms_id: number;
  question_lms_id: number;
}

export interface GetGradingQuestionSubmissionsJobRequest {
  lms_id: number;
  course_lms_id: number;
  assignment_lms_id: number;
  question_lms_id: number;
}

export interface TaskProgress {
  state: "PENDING" | "SUCCESS" | string;
  complete: boolean;
  success: boolean | null;
  progress: {
    pending: boolean;
    current: number;
    total: number;
    percent: number;
    description: string | null;
  };
  result: any | null;
}

export interface GetTaskProgressRequest {
  taskId: string;
}

export interface UpdateGradingQuestionSubmissionsJobRequest {
  lms: number;
  course_lms_id: number;
  assignment_lms_id: number;
  question_lms_id: number;
  status: "terminated";
}

export const extendedApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // -----------------------------------------------------------------
    getSubmissionGradingResult: builder.mutation<
      GraderResult,
      GetSubmissionGraderRequest
    >({
      query: (data) => {
        return {
          url: "grading/grade_submission/",
          method: "post",
          body: {
            engine_token: data.engine_token,
            lms_id: data.lms_id,
            course_lms_id: data.course_lms_id,
            assignment_lms_id: data.assignment_lms_id,
            question_lms_id: data.question_lms_id,
            student_lms_id: data.student_lms_id,
            submission_lms_id: data.submission_lms_id,
            question_text: data.question_text,
            solution: data.solution,
            answer: data.answer,
            rubric: data.rubric,
            points_possible: data.points_possible,
          },
        };
      },
    }),
    // -----------------------------------------------------------------
    gradeAllQuestionSubmissions: builder.mutation<
      {},
      GradeAllQuestionSubmissionsRequest
    >({
      query: (data) => {
        return {
          url: "grading/grade_all_question_submissions/",
          method: "post",
          body: {
            engine_token: data.engine_token,
            lms_id: data.lms_id,
            user_lms_id: data.user_lms_id,
            course_lms_id: data.course_lms_id,
            assignment_lms_id: data.assignment_lms_id,
            question_lms_id: data.question_lms_id,
            top_n_submissions: data.top_n_submissions,
            skip_graded_submissions: data.skip_graded_submissions,
            rubric: data.rubric,
          },
        };
      },
    }),

    // -----------------------------------------------------------------
    submitAllQuestionSubmissions: builder.mutation<
      {},
      SubmitAllQuestionSubmissionsRequest
    >({
      query: (data) => {
        return {
          url: "grading/submit_all_question_submissions/",
          method: "post",
          body: {
            lms_id: data.lms_id,
            user_lms_id: data.user_lms_id,
            course_lms_id: data.course_lms_id,
            assignment_lms_id: data.assignment_lms_id,
            question_lms_id: data.question_lms_id,
          },
        };
      },
    }),

    // -----------------------------------------------------------------
    updateGradingQuestionSubmissionsJob: builder.mutation<
      {},
      UpdateGradingQuestionSubmissionsJobRequest
    >({
      query: (data) => {
        return {
          url: "grading/update_grading_question_submissions_job/",
          method: "post",
          body: {
            lms: data.lms,
            course_lms_id: data.course_lms_id,
            assignment_lms_id: data.assignment_lms_id,
            question_lms_id: data.question_lms_id,
            status: data.status,
          },
        };
      },
    }),

    // -----------------------------------------------------------------
    terminateGradingQuestionSubmissionsJob: builder.mutation<
      {},
      { job_id: number }
    >({
      query: (data) => {
        return {
          url: `grading/terminate_grading_question_submissions_job/${data.job_id}/`,
          method: "put",
        };
      },
    }),

    // -----------------------------------------------------------------
    getGradingQuestionSubmissionsJob: builder.query<
      ActiveJobResult,
      GetGradingQuestionSubmissionsJobRequest
    >({
      query: (data) => {
        return {
          url: "grading/get_grading_question_submissions_job/",
          method: "post",
          body: {
            lms: data.lms_id,
            course_lms_id: data.course_lms_id,
            assignment_lms_id: data.assignment_lms_id,
            question_lms_id: data.question_lms_id,
          },
        };
      },
    }),

    // -----------------------------------------------------------------

    getTaskProgress: builder.query<TaskProgress, GetTaskProgressRequest>({
      query: (data) => {
        return {
          url: `celery-progress/${data.taskId}/`,
          method: "get",
        };
      },
    }),

    // -----------------------------------------------------------------
  }),
});

export const {
  useGetSubmissionGradingResultMutation,
  useGradeAllQuestionSubmissionsMutation,
  useSubmitAllQuestionSubmissionsMutation,
  useLazyGetGradingQuestionSubmissionsJobQuery,
  useGetGradingQuestionSubmissionsJobQuery,
  useGetTaskProgressQuery,
  useLazyGetTaskProgressQuery,
  useUpdateGradingQuestionSubmissionsJobMutation,
  useTerminateGradingQuestionSubmissionsJobMutation,
} = extendedApiSlice;
