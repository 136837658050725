interface configItem {
  backend_base_url: string;
}

// -------------------------------------------------------------------------------------
export const ENVIRONMENT = process.env.REACT_APP_ENV || "test";
// -------------------------------------------------------------------------------------
const config: { [env: string]: configItem } = {
  test: {
    backend_base_url: "https://test-api.inteligrade.com/",
  },
  prod: {
    backend_base_url: "https://test-api.inteligrade.com/",
  },
  local: {
    backend_base_url: "http://localhost:8000/",
  },
};

// -------------------------------------------------------------------------------------
// EXAMPLE USAGE:
const backend_base_url = config[ENVIRONMENT].backend_base_url;

export default config;
