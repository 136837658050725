export default function getDayNameAndTime(dateTimeString: string) {
  // Parse the date and time string into a Date object
  const date = new Date(dateTimeString);

  // Get the local date and time components
  const localYear = date.getFullYear();
  const localMonth = date.getMonth() + 1; // Months are zero-based, so we add 1
  const localDay = date.getDate();
  const localHours = date.getHours();
  const localMinutes = date.getMinutes();
  const localSeconds = date.getSeconds();

  // Array of weekday names
  const daysOfWeek = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  // Get the day index from 0 to 6 (Sunday to Saturday)
  const dayIndex = date.getDay();

  // Get the day name
  const dayName = daysOfWeek[dayIndex];

  // Convert hours to 12-hour format and determine AM/PM
  const hours12 = localHours % 12 || 12;
  const amPm = localHours >= 12 ? "PM" : "AM";

  // Create a formatted string with the date and time
  const formattedDateTime = `(${localYear}-${localMonth}-${localDay}) ${hours12
    .toString()}:${localMinutes
    .toString()
    .padStart(2, "0")} ${amPm}`;

  // Return an object with the day name and formatted date and time
  return formattedDateTime;
}
