import React from "react";

import { useParams, Link } from "react-router-dom";
import { useGetCoursesQuery } from "./coursesApiSlice";
import CoursesList from "./coursesList";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { setLms } from "../lmsSlice";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import HomeIcon from "@mui/icons-material/Home";
import CircularProgress from "@mui/material/CircularProgress";

const CoursesPage: React.FC = () => {
  const workingLms = useAppSelector((state) => state.lms.workingLms);
  const lmsID = useParams().lmsId;
  const dispatch = useAppDispatch();
  const {
    data: coursesData = [],
    error,
    isLoading,
    isFetching,
    isSuccess,
  } = useGetCoursesQuery({
    lms_type: workingLms && workingLms.lmsType!,
    base_url: workingLms && workingLms.lmsBaseUrl!,
    access_token: workingLms && workingLms.lmsAccessToken!,
  });
  // console.log(coursesData);

  return (
    <div>
      <div className="grey-title-container">
        <Breadcrumbs aria-label="breadcrumb">
          <Link
            className="link"
            style={{ display: "flex", alignItems: "center" }}
            color="inherit"
            to="/"
          >
            <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
            Home
          </Link>
          <Link
            className="link"
            style={{ display: "flex", alignItems: "center" }}
            color="inherit"
            to={`/workspaces`}
          >
            Workspaces
          </Link>
          {/* <Link
            className="link"
            style={{ display: "flex", alignItems: "center" }}
            color="inherit"
            to={`/workspaces/${lmsID}/courses`}
          >
            Courses
          </Link> */}
          <div>Courses</div>
        </Breadcrumbs>
      </div>
      <div className="grey-bordered-container">
        {isLoading ? (
          <CircularProgress />
        ) : (
          <CoursesList coursesList={coursesData} />
        )}
      </div>
    </div>
  );
};

export default CoursesPage;
