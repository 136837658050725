import React, { useState, useEffect, ChangeEvent } from "react";
import { LoadingButton } from "@mui/lab";
import Button from "@mui/material/Button";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { logout } from "../authentication/authSlice";
import { useNavigate } from "react-router-dom";
import { useGetUserProfileQuery } from "./userProfileApiSlice";
import LMSList from "../../legacy/lmsListOld";
import { textAlign } from "@mui/system";
import EngineTokenDialog from "./engineTokenDialog";
import LMSListNew from "../lmsManagement/lmsList";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { Card, CardHeader, Typography } from "@mui/material";
import { useGetSubmissionGradingResultMutation } from "../grader/graderApiSlice";
import { ScrollNumberProps } from "antd/es/badge";

function capitalizeFirstLetter(word: string) {
  return word.charAt(0).toUpperCase() + word.slice(1);
}

const PlayGroundPage = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const user = useAppSelector((state) => state.auth.user);
  const [maxPoints, setMaxPoints] = useState<number>(1);
  const [feedback, setFeedback] = useState("");
  const [grade, setGrade] = useState(0);

  const handleMaxPointsChange = (event: ChangeEvent<HTMLInputElement>) => {
    setMaxPoints(Number(event.target.value));
  };

  const {
    data: profileData,
    error,
    isLoading,
    isFetching,
    isSuccess,
  } = useGetUserProfileQuery();

  const [gradeSubmission, SubmissionGradingResult] =
    useGetSubmissionGradingResultMutation();

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const question = data.get("question") as string;
    const solution = data.get("solution") as string;
    const answer = data.get("answer") as string;
    const rubric = data.get("rubric") as string;
    await gradeSubmission({
      // engine_token: "",
      // lms_id: null,
      // course_lms_id: null,
      // assignment_lms_id: null,
      // question_lms_id: null,
      // student_lms_id: null,
      // submission_lms_id: null,
      question_text: question,
      solution: solution,
      answer: answer,
      rubric: rubric,
      points_possible: maxPoints,
    });
  };

  useEffect(() => {
    if (SubmissionGradingResult.isSuccess) {
      const result = SubmissionGradingResult.data
        ? {
            grade: SubmissionGradingResult.data.result.grade!,
            feedback: SubmissionGradingResult.data.result.feedback!,
            created_at: SubmissionGradingResult.data.result.created_at!,
          }
        : { grade: 0, feedback: "", created_at: "" };
      setFeedback(result.feedback);
      setGrade(result.grade);
    }
  }, [SubmissionGradingResult]);

  return (
    <div>
      <div style={{ fontSize: 20, margin: "30px" }}>Playground</div>

      <Box component="form" onSubmit={handleSubmit}>
        <TextField
          type="number"
          label="Max points possible"
          name="max_points"
          variant="outlined"
          defaultValue={1}
          required
          style={{ marginTop: "16px" }}
          inputProps={{
            min: 1,
          }}
          onChange={handleMaxPointsChange}
        />
        <TextField
          fullWidth
          multiline
          rows={10}
          required
          label="Question"
          name="question"
          variant="outlined"
          style={{ marginTop: "16px" }}
        />
        <TextField
          fullWidth
          multiline
          rows={10}
          label="Correct Solution (reference key)"
          name="solution"
          variant="outlined"
          style={{ marginTop: "16px" }}
        />
        <TextField
          fullWidth
          multiline
          rows={10}
          label="Student Answer (to be graded)"
          name="answer"
          variant="outlined"
          style={{ marginTop: "16px" }}
        />
        <TextField
          fullWidth
          multiline
          rows={5}
          label="Ruibric (specify grading criteria)"
          name="rubric"
          variant="outlined"
          style={{ marginTop: "16px" }}
        />
        {/* <Button
          variant="contained"
          fullWidth
          onClick={() => {}}
          style={{ margin: "auto", marginTop: "16px" }}
        >
          Grade
        </Button> */}
        <Card
          raised={true}
          sx={{
            width: "100%",
            backgroundColor: "#f5f5f5",
            border: "1px solid lightgrey",
            borderRadius: "10px",
            marginTop: "16px",
            marginBottom: "100px",
          }}
        >
          {/* <CardHeader sx={{ backgroundColor: "white" }} title={"Title"} /> */}
          <div className="grey-bordered-submission-container">
            <Box>
              <p style={{ margin: "5px", fontSize: 15, fontWeight: "bold" }}>
                {"InteliGrade Feedback:"}
              </p>
              <div
                className="white-bordred-container"
                style={{ overflowWrap: "break-word", fontSize: 15 }}
              >
                {feedback}
              </div>
              <p
                style={{ margin: "5px", fontSize: 15, fontWeight: "bold" }}
              >{`Grade: ${grade === undefined ? "-" : grade}/ ${maxPoints}`}</p>

              <LoadingButton
                style={{ margin: "5px" }}
                fullWidth
                type="submit"
                loading={SubmissionGradingResult.isLoading}
                variant="contained"
                // onClick={onGradeClick}
              >
                Grade
              </LoadingButton>
            </Box>
          </div>
        </Card>
      </Box>
    </div>
  );
};

export default PlayGroundPage;
