import { createSelector, createEntityAdapter } from "@reduxjs/toolkit";
import { apiSlice } from "../../api/apiSlice";
import { Student } from "../lmsModels";

export interface GetAssignmentStudentsRequest {
  lms_type: string;
  base_url: string;
  access_token: string;
  courseId: number;
  assignmentId: number;
}

export const extendedApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAssignmentStudents: builder.query<
      Student[],
      GetAssignmentStudentsRequest
    >({
      query: (data) => {
        return {
          url: "lms_api/get_assignment_students/",
          method: "post",
          body: {
            lms_type: data.lms_type,
            base_url: data.base_url,
            access_token: data.access_token,
            course_id: data.courseId,
            assignment_id: data.assignmentId,
            page: 1,
            per_page: 1000,
          },
        };
      },
    }),
  }),
});

export const { useGetAssignmentStudentsQuery } = extendedApiSlice;
