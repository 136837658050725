import * as React from "react";
import { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import {
  useDeleteUserLmsMutation,
  useGetLatestQuestionSubmissionsQuery,
} from "../../../lmsManagement/lmsManagementApiSlice";
import {
  useGradeAllQuestionSubmissionsMutation,
  useSubmitAllQuestionSubmissionsMutation,
} from "../../../grader/graderApiSlice";
import { useAppSelector } from "../../../../redux/hooks";
import { useParams } from "react-router-dom";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import UploadIcon from "@mui/icons-material/Upload";

interface Props {
  disabled: boolean;
  refetch: () => void;
}

const SubmitGradesDialog: React.FC<Props> = ({ disabled, refetch }) => {
  const [open, setOpen] = React.useState(false);
  const workingLms = useAppSelector((state) => state.lms.workingLms);
  const engineToken = useAppSelector((state) => state.lms.engineToken);
  const rubrics = useAppSelector((state) => state.lms.lmsRubrics);
  const courseID = useParams().courseID;
  const assignmentID = useParams().assignmentID;
  const questionID = useParams().questionID;

  const [SubmitAllQuestionSubmissions, SubmitAllQuestionSubmissionsResults] =
    useSubmitAllQuestionSubmissionsMutation();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const SubmitQuestionSubmissionGrades = () => {
    SubmitAllQuestionSubmissions({
      lms_id: workingLms.lmsId!,
      user_lms_id: workingLms.userLmsId!,
      course_lms_id: +courseID!,
      assignment_lms_id: +assignmentID!,
      question_lms_id: +questionID!,
    }).then(refetch);
  };

  useEffect(() => {}, []);

  const {
    data: QuestionSubmissionsData,
    error: QuestionSubmissionsEror,
    isLoading: QuestionSubmissionsIsLoading,
    isFetching: QuestionSubmissionsIsFetching,
    isSuccess: QuestionSubmissionsIsSuccess,
  } = useGetLatestQuestionSubmissionsQuery({
    lmsId: workingLms && workingLms.lmsId!,
    courseLmsId: +courseID!,
    assignmentLmsId: +assignmentID!,
    questionLmsId: +questionID!,
  });

  const [checked, setChecked] = React.useState(false);

  const handleCheckBoxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };

  return (
    <div style={{ textAlign: "center", margin: "auto" }}>
      <Button
        disabled={disabled}
        fullWidth
        variant="contained"
        onClick={handleClickOpen}
        endIcon={<UploadIcon sx={{ mr: 0.5 }} fontSize="inherit" />}
      >
        Submit Grades and Feedbacks
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Submit All Submission Grades and Feedbacks?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to submit all submissions?
          </DialogContentText>
          <DialogContentText id="alert-dialog-description">
            {
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checked}
                    onChange={handleCheckBoxChange}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                }
                label="I confirm that the garding results look and wish to submit"
                labelPlacement="end"
              />
            }
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ display: "flex", flexDirection: "column" }}>
          <div style={{ width: "100%" }}>
            <Button
              disabled={!checked}
              sx={{ marginBottom: "10px" }}
              fullWidth
              variant="contained"
              onClick={() => {
                handleClose();
                SubmitQuestionSubmissionGrades();
              }}
              autoFocus
            >
              Submit
            </Button>
            <Button
              sx={{ marginy: "10px" }}
              fullWidth
              variant="outlined"
              onClick={() => {
                handleClose();
              }}
            >
              Cancel
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default SubmitGradesDialog;
