import * as React from "react";
import { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import {
  useDeleteUserLmsMutation,
  useGetLatestQuestionSubmissionsQuery,
} from "../../../lmsManagement/lmsManagementApiSlice";
import { useGradeAllQuestionSubmissionsMutation } from "../../../grader/graderApiSlice";
import { useAppSelector } from "../../../../redux/hooks";
import { useParams } from "react-router-dom";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import MemoryIcon from "@mui/icons-material/Memory";

interface Props {
  disabled: boolean;
  refetch: () => void;
  //   lmsId: number;
  //   lmsListrefetch: () => void;
}

const ByQuestionGradeAllDialog: React.FC<Props> = ({
  disabled,
  refetch,
  //   lmsId,
  //   lmsListrefetch,
}) => {
  const [allowGradeAll, setAllowGradeAll] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const workingLms = useAppSelector((state) => state.lms.workingLms);
  const engineToken = useAppSelector((state) => state.lms.engineToken);
  const rubrics = useAppSelector((state) => state.lms.lmsRubrics);
  const courseID = useParams().courseID;
  const assignmentID = useParams().assignmentID;
  const questionID = useParams().questionID;

  const [GradeAllQuestionSubmissions, GradeAllQuestionSubmissionsResults] =
    useGradeAllQuestionSubmissionsMutation();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const gradeTopNSubmisssions = (
    top_n_submissions: number,
    skip_graded_submissions: boolean
  ) => {
    console.log(top_n_submissions);
    GradeAllQuestionSubmissions({
      engine_token: engineToken!,
      lms_id: workingLms.lmsId!,
      user_lms_id: workingLms.userLmsId!,
      course_lms_id: +courseID!,
      assignment_lms_id: +assignmentID!,
      question_lms_id: +questionID!,
      top_n_submissions: top_n_submissions,
      skip_graded_submissions: skip_graded_submissions,
      rubric: rubrics[+questionID!],
    }).then(refetch);
  };

  const gradeAllSubmisssions = (skip_graded_submissions: boolean) => {
    GradeAllQuestionSubmissions({
      engine_token: engineToken!,
      lms_id: workingLms.lmsId!,
      user_lms_id: workingLms.userLmsId!,
      course_lms_id: +courseID!,
      assignment_lms_id: +assignmentID!,
      question_lms_id: +questionID!,
      top_n_submissions: -1,
      skip_graded_submissions: skip_graded_submissions,
      rubric: rubrics[+questionID!],
    }).then(refetch);
  };

  useEffect(() => {}, []);

  const {
    data: QuestionSubmissionsData,
    error: QuestionSubmissionsEror,
    isLoading: QuestionSubmissionsIsLoading,
    isFetching: QuestionSubmissionsIsFetching,
    isSuccess: QuestionSubmissionsIsSuccess,
  } = useGetLatestQuestionSubmissionsQuery({
    lmsId: workingLms && workingLms.lmsId!,
    courseLmsId: +courseID!,
    assignmentLmsId: +assignmentID!,
    questionLmsId: +questionID!,
  });

  useEffect(() => {
    if (QuestionSubmissionsData) {
      if (Object.keys(QuestionSubmissionsData).length >= 5) {
        setAllowGradeAll(true);
      }
    }
  }, [QuestionSubmissionsIsSuccess]);

  const [checked, setChecked] = React.useState(false);
  const [skip, setSkipChecked] = React.useState(false);

  const handleCheckBoxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };

  const handleSkipCheckBoxChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSkipChecked(event.target.checked);
  };

  return (
    <div style={{ textAlign: "center", margin: "auto" }}>
      <Button
        disabled={disabled}
        fullWidth
        variant="contained"
        onClick={handleClickOpen}
        startIcon={<MemoryIcon sx={{ mr: 0.5 }} fontSize="inherit" />}
      >
        Grade All Submissions
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Grade All Submissions?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to grade all submissions?
          </DialogContentText>
          <DialogContentText id="alert-dialog-description">
            {allowGradeAll && (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checked}
                    onChange={handleCheckBoxChange}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                }
                label="I confirm that the grading criteria is correct and wish to grade all submissions"
                labelPlacement="end"
              />
            )}
            <FormControlLabel
              control={
                <Checkbox
                  checked={skip}
                  onChange={handleSkipCheckBoxChange}
                  inputProps={{ "aria-label": "controlled" }}
                />
              }
              label="Skip graded submissions (leave unchecked to re-grade)"
              labelPlacement="end"
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ display: "flex", flexDirection: "column" }}>
          <div style={{ width: "100%" }}>
            <Button
              sx={{ marginBottom: "10px" }}
              fullWidth
              variant="outlined"
              onClick={() => {
                handleClose();
                gradeTopNSubmisssions(5, skip);
              }}
              autoFocus
            >
              Grade First 5
            </Button>
            <Button
              // disabled={!allowGradeAll}
              sx={{ marginBottom: "10px" }}
              fullWidth
              variant="contained"
              disabled={!checked || !allowGradeAll}
              onClick={() => {
                handleClose();
                gradeAllSubmisssions(skip);
              }}
              autoFocus
            >
              Grade All
            </Button>

            <Button
              sx={{ marginy: "10px" }}
              fullWidth
              variant="outlined"
              onClick={() => {
                handleClose();
              }}
            >
              Cancel
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ByQuestionGradeAllDialog;
