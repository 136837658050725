import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/dist/query";
import { apiSlice } from "../features/api/apiSlice";
import authSlice from "../features/authentication/authSlice";
import lmsSlice from "../features/lms/lmsSlice";
import graderSlice from "../features/grader/graderSlice";
// import { canvasApi } from "../legacy/canvas/canvasApi";
// import canvasReducer from "../legacy/canvas/canvasSlice";
// import { smartGraderApi } from "../legacy/smartGrader/smartGraderApi";

export const store = configureStore({
  reducer: {
    // [canvasApi.reducerPath]: canvasApi.reducer,
    // [smartGraderApi.reducerPath]: smartGraderApi.reducer,
    // canvas: canvasReducer,
    [apiSlice.reducerPath]: apiSlice.reducer,
    auth: authSlice,
    lms: lmsSlice,
    grader: graderSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      // canvasApi.middleware,
      // smartGraderApi.middleware,
      apiSlice.middleware
    ),
});

setupListeners(store.dispatch);
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
