import { createSelector, createEntityAdapter } from "@reduxjs/toolkit";
import { apiSlice } from "../../api/apiSlice";
import { Assignment, Course } from "../lmsModels";

export interface GetAssignmentsRequest {
  lms_type: string;
  base_url: string;
  access_token: string;
  courseId: number;
}

export const extendedApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAssignments: builder.query<Assignment[], GetAssignmentsRequest>({
      query: (data) => {
        return {
          url: "lms_api/get_assignments/",
          method: "post",
          body: {
            lms_type: data.lms_type,
            base_url: data.base_url,
            access_token: data.access_token,
            course_id: data.courseId,
          },
        };
      },
    }),
  }),
});

export const { useGetAssignmentsQuery } = extendedApiSlice;
