import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../redux/hooks";
import { useRefreshTokenMutation } from "./authApiSlice";
import { logout, setAuthTokens } from "./authSlice";

const RefreshTokenController: React.FC = () => {
  // const [intervalId, setIntervalId] = useState<number | null>(null);
  const intervalIDRef = useRef<NodeJS.Timeout | undefined>();
  const dispatch = useDispatch();
  const authTokens = useAppSelector((state) => state.auth.authTokens);
  const [refreshToken, refreshTokenResult] = useRefreshTokenMutation();
  const refreshInterval = 1000 * 60 * 60;

  const refresh = async () => {
    if (authTokens) {
      console.log("Refreshing token!");
      await refreshToken({ refresh: authTokens.refresh });
      // console.log(refreshTokenResult.error);
      // console.log(refreshTokenResult.data);
    }
  };

  // useEffect(() => {
  //   if (authTokens) {
  //     // set up the interval to refresh the token every 2 minutes
  //     const intervalId = setInterval(() => {
  //       refresh();
  //     }, refreshInterval);

  //     // clean up the interval on component unmount
  //     return () => clearInterval(intervalId);
  //   }
  // });

  useEffect(() => {
    if (authTokens) {
      refresh();
    }
  }, []);

  useEffect(() => {
    if (authTokens) {
      intervalIDRef.current = setInterval(() => {
        refresh();
      }, refreshInterval);

      // clean up the interval on component unmount
      return () => clearInterval(intervalIDRef.current);
    }
  });

  useEffect(() => {
    // console.log(refreshTokenResult.requestId);
    if (refreshTokenResult.isError) {
      dispatch(logout());
    }
    if (refreshTokenResult.data) {
      if (refreshTokenResult.isSuccess) {
        dispatch(setAuthTokens({ authTokens: refreshTokenResult.data }));
      }
    }
  }, [refreshTokenResult]);

  return null;
};

export default RefreshTokenController;
