import React from "react";
import { useParams, Link } from "react-router-dom";
import {
  AssignmentSubmission,
  Question,
  QuestionSubmission,
  Student,
} from "../../lmsModels";
import StudentQuestionSubmissionItem from "./studentQuestionSubmissionItem";
import { Divider, List } from "@mui/material";

interface StudentQuestionSubmissionsListProps {
  StudentAssignmentSubmission: AssignmentSubmission;
}

const StudentQuestionsSubmissionList: React.FC<
  StudentQuestionSubmissionsListProps
> = ({ StudentAssignmentSubmission }) => {
  return (
    StudentAssignmentSubmission!.question_submissions! && (
      <List sx={{ width: "100%", bgcolor: "background.paper" }}>
        {Object.values(StudentAssignmentSubmission!.question_submissions!)
          .flat()
          .map((item, index) => (
            <React.Fragment key={index}>
              <StudentQuestionSubmissionItem
                StudentQuestionsSubmission={item}
                index={index}
              />
              {index !==
                Object.values(
                  StudentAssignmentSubmission!.question_submissions!
                ).flat().length -
                  1 && <Divider sx={{ margin: "10px" }} />}
            </React.Fragment>
          ))}
      </List>
    )
  );
};

export default StudentQuestionsSubmissionList;
