import React, { useState, useEffect } from "react";
import { List, Input } from "antd";
import { useParams, Link } from "react-router-dom";
import { Question, QuestionSubmission } from "../../lmsModels";
import { LoadingButton } from "@mui/lab";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { addGraderResult } from "../../../grader/graderSlice";
import { useGetSubmissionGradingResultMutation } from "../../../grader/graderApiSlice";
import {
  useGetAssignmentGradingStatusQuery,
  useGetLatestQuestionSubmissionsQuery,
} from "../../../lmsManagement/lmsManagementApiSlice";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import formattedDateTime from "../../../utils/utilFunctions";

import DOMPurify from "dompurify";
import { Box, CardHeader, Grid } from "@mui/material";

interface QuestionSubmissionItemProps {
  question: Question;
  questionSubmission: QuestionSubmission;
  index: number;
}

const QuestionSubmissionItem: React.FC<QuestionSubmissionItemProps> = ({
  question,
  questionSubmission,
  index,
}) => {
  const dispatch = useAppDispatch();

  const { TextArea } = Input;

  const [feedback, setFeedback] = useState("");
  const [grade, setGrade] = useState(0);
  const [createdAt, setCreatedAt] = useState("");

  const lmsID = useParams().lmsId;
  const graderResults = useAppSelector((state) => state.grader.graderResults);
  const engineToken = useAppSelector((state) => state.lms.engineToken);
  const rubrics = useAppSelector((state) => state.lms.lmsRubrics);
  const workingLms = useAppSelector((state) => state.lms.workingLms);
  const courseID = useParams().courseID;
  const assignmentID = useParams().assignmentID;
  const questionID = useParams().questionID;

  const [gradeSubmission, SubmissionGradingResult] =
    useGetSubmissionGradingResultMutation();

  const {
    data: statusData,
    refetch: statusRefetch,
    error: statusError,
    isLoading: statusIsLoading,
    isFetching: statusIsFetching,
    isSuccess: statusIsSuccess,
  } = useGetAssignmentGradingStatusQuery({
    lmsId: +lmsID!,
    courseLmsId: +courseID!,
    assignmentLmsId: +assignmentID!,
    questionLmsId: null,
  });

  const onGradeClick = async () => {
    await gradeSubmission({
      engine_token: engineToken!,
      lms_id: workingLms && workingLms.lmsId!,
      course_lms_id: +courseID!,
      assignment_lms_id: +assignmentID!,
      question_lms_id: +questionID!,
      student_lms_id: questionSubmission.student.id,
      submission_lms_id: questionSubmission.id,
      question_text: question.question_text,
      solution: question.solution,
      answer: questionSubmission.answer,
      rubric: rubrics[+questionID!] ? rubrics[+questionID!] : "",
      points_possible: question.points_possible,
    });
    statusRefetch();
  };

  const questionGradedResult = () => {
    let feedback;
    let grade;
    let created_at;
    if (question.id in graderResults) {
      if (questionSubmission.student.id in graderResults[question.id]) {
        feedback =
          graderResults[question.id][questionSubmission.student.id].result
            .feedback;
        grade =
          graderResults[question.id][questionSubmission.student.id].result
            .grade;
        created_at =
          graderResults[question.id][questionSubmission.student.id].result
            .created_at;
        setFeedback(feedback);
        setGrade(grade);
        setCreatedAt(created_at);
      }
    } else {
      feedback = "";
      grade = 0;
      created_at = "";
    }
  };

  useEffect(() => {
    if (SubmissionGradingResult.isSuccess) {
      const result = SubmissionGradingResult.data
        ? {
            grade: SubmissionGradingResult.data.result.grade!,
            feedback: SubmissionGradingResult.data.result.feedback!,
            created_at: SubmissionGradingResult.data.result.created_at!,
          }
        : { grade: 0, feedback: "", created_at: "" };
      dispatch(
        addGraderResult({
          question_id: +questionID!,
          student_id: questionSubmission.student.id,
          result: {
            result: result,
          },
        })
      );
      setFeedback(result.feedback);
      setGrade(result.grade);
      setCreatedAt(result.created_at);
    }
  }, [SubmissionGradingResult]);

  useEffect(() => {
    questionGradedResult();
  });

  const {
    data: QuestionSubmissionsData,
    error: QuestionSubmissionsEror,
    isLoading: QuestionSubmissionsIsLoading,
    isFetching: QuestionSubmissionsIsFetching,
    isSuccess: QuestionSubmissionsIsSuccess,
  } = useGetLatestQuestionSubmissionsQuery({
    lmsId: workingLms && workingLms.lmsId!,
    courseLmsId: +courseID!,
    assignmentLmsId: +assignmentID!,
    questionLmsId: +questionID!,
  });

  useEffect(() => {
    if (QuestionSubmissionsData) {
      const feedback =
        QuestionSubmissionsData[String(questionSubmission.student.id)] &&
        QuestionSubmissionsData[String(questionSubmission.student.id)].feedback;
      setFeedback(feedback);
      const grade =
        QuestionSubmissionsData[String(questionSubmission.student.id)] &&
        QuestionSubmissionsData[String(questionSubmission.student.id)].grade;
      setGrade(grade);
      const created_at =
        QuestionSubmissionsData[String(questionSubmission.student.id)] &&
        QuestionSubmissionsData[String(questionSubmission.student.id)]
          .created_at;
      setCreatedAt(created_at);
    }
  }, [QuestionSubmissionsIsSuccess]);

  return (
    <Grid container>
      <ListItem
        sx={{
          width: "100%",
          paddingX: "15px",
        }}
      >
        <Card
          raised={true}
          sx={{
            width: "100%",
            backgroundColor: "#f5f5f5",
            border: "1px solid lightgrey",
            borderRadius: "10px",
          }}
        >
          <CardHeader
            sx={{ backgroundColor: "white" }}
            avatar={<Avatar src={questionSubmission.student.avatar_url} />}
            title={
              <Typography sx={{ fontWeight: "bold" }}>
                {/* {`${index + 1}. ${questionSubmission.student.name}`} */}
                <Link
                  className="link"
                  to={`/workspaces/${lmsID}/courses/${courseID}/assignments/${assignmentID}/students/${questionSubmission.student.id}`}
                >
                  {`${index + 1}. ${questionSubmission.student.name}`}
                </Link>
              </Typography>
            }
            subheader={`ID: ${questionSubmission.student.id}`}
          />
          <div className="grey-bordered-submission-container">
            <Box>
              <p style={{ margin: "5px", fontSize: 15, fontWeight: "bold" }}>
                {"Answer:"}
              </p>
              <div
                className="white-bordred-container"
                style={{
                  fontSize: 15,
                  overflow: "auto",
                }}
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(questionSubmission.answer),
                }}
              ></div>

              <p style={{ margin: "5px", fontSize: 15, fontWeight: "bold" }}>
                {"Submitted Feedback:"}
              </p>
              {/* <TextArea
                showCount
                maxLength={1000}
                style={{
                  fontSize: 15,
                  margin: "5px",
                  height: 80,
                  resize: "none",
                }}
                placeholder="Submitted Feedback "
                defaultValue={questionSubmission.comments}
              ></TextArea> */}
              <div
                className="white-bordred-container"
                style={{ overflowWrap: "break-word", fontSize: 15 }}
              >
                {questionSubmission.comments}
              </div>
              <p
                style={{ margin: "5px", fontSize: 15, fontWeight: "bold" }}
              >{`Submitted Grade: ${questionSubmission.points}/ ${question.points_possible}`}</p>
              <Divider />
              <p style={{ margin: "5px", fontSize: 15, fontWeight: "bold" }}>
                {"AI Feedback:"}
              </p>
              <div
                className="white-bordred-container"
                style={{ overflowWrap: "break-word", fontSize: 15 }}
              >
                {feedback}
              </div>
              <p
                style={{ margin: "5px", fontSize: 15, fontWeight: "bold" }}
              >{`AI Grade: ${grade === undefined ? "-" : grade}/ ${
                question.points_possible
              }`}</p>

              <LoadingButton
                style={{ margin: "5px" }}
                loading={SubmissionGradingResult.isLoading}
                variant="contained"
                onClick={onGradeClick}
              >
                Grade
              </LoadingButton>
              {createdAt && (
                <p
                  style={{
                    margin: "0px",
                    color: "grey",
                    textAlign: "center",
                    fontSize: "0.8rem",
                  }}
                >
                  Last graded at: {formattedDateTime(createdAt)}
                </p>
              )}
            </Box>
          </div>
        </Card>
      </ListItem>
    </Grid>
  );
};

export default QuestionSubmissionItem;
