import React from "react";
import { useParams, Link } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import HomeIcon from "@mui/icons-material/Home";
import LMSList from "./lmsList";

const WorkspacesPage: React.FC = () => {
  const workingLms = useAppSelector((state) => state.lms.workingLms);
  const dispatch = useAppDispatch();

  return (
    <div>
      <div className="grey-title-container">
        <Breadcrumbs aria-label="breadcrumb">
          <Link
            className="link"
            style={{ display: "flex", alignItems: "center" }}
            color="inherit"
            to="/"
          >
            <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
            Home
          </Link>
          {/* <Link
            className="link"
            style={{ display: "flex", alignItems: "center" }}
            color="inherit"
            to={`/workspaces`}
          >
            Workspaces
          </Link> */}
          <div>Workspaces</div>
        </Breadcrumbs>
      </div>
      {/* <p className="grey-title-container">My Workspaces</p> */}
      <div className="grey-bordered-container">
        <LMSList />
      </div>
    </div>
  );
};

export default WorkspacesPage;
