import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const workingLmsData = localStorage.getItem("workingLms");
const engineToken = localStorage.getItem("engineToken");

interface LmsState {
  workingLms: {
    lmsId: number | null;
    userLmsId: number | null;
    lmsType: string | null;
    lmsBaseUrl: string | null;
    lmsAccessToken: string | null;
  };
  engineToken: string | null;
  lmsRubrics: { [id: number]: string };
  filterByKey: string;
}

const initialState: LmsState = {
  workingLms: workingLmsData ? JSON.parse(workingLmsData) : null,
  engineToken: engineToken ? engineToken : "<Token>",
  lmsRubrics: {},
  filterByKey: "byQuestion",
};

export const lmsSlice = createSlice({
  name: "lms",

  initialState: initialState,

  reducers: {
    setLms: (
      state,
      action: PayloadAction<{
        workingLms: {
          lmsId: number;
          userLmsId: number;
          lmsType: string;
          lmsBaseUrl: string;
          lmsAccessToken: string;
        };
      }>
    ) => {
      localStorage.setItem(
        "workingLms",
        JSON.stringify(action.payload.workingLms)
      );
      state.workingLms = action.payload.workingLms;
    },

    setEngineToken: (
      state,
      action: PayloadAction<{
        engineToken: string;
      }>
    ) => {
      localStorage.setItem("engineToken", action.payload.engineToken);
      state.engineToken = action.payload.engineToken;
    },

    addLmsRubric: (state, action) => {
      const { question_id, rubric } = action.payload;
      state.lmsRubrics[question_id] = rubric;
    },
    setFilterByKey: (state, action) => {
      state.filterByKey = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setLms, setEngineToken, addLmsRubric, setFilterByKey } =
  lmsSlice.actions;

export default lmsSlice.reducer;
