import React from "react";
// import { Avatar, List, Card, Spin, Button } from "antd";
import { useParams, Link } from "react-router-dom";
import AssignmentItem from "./assignmentItem";
import { Assignment, Course } from "../lmsModels";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";

interface AssignmentsListProps {
  AssignmentsList: Assignment[];
}

const AssignmentsList: React.FC<AssignmentsListProps> = ({
  AssignmentsList,
}) => {
  const lmsID = useParams().lmsId;
  const courseID = useParams().courseID;
  return (
    // <List
    //   size="small"
    //   itemLayout="horizontal"
    //   dataSource={AssignmentsList}
    //   renderItem={(item, index) => (
    //     <AssignmentItem assignment={item} index={index} />
    //   )}
    // />
    <List sx={{ width: "100%", bgcolor: "background.paper" }}>
      {AssignmentsList.map((assignment, index) => (
        <React.Fragment key={index}>
          <ListItem
            alignItems="flex-start"
            sx={{ width: "100%" }}
            // component={Link}
            // to={`/lms/courses/${course.id}`}
          >
            {/* <ListItemAvatar>
          <Avatar alt={course.name} src="/static/images/avatar/1.jpg" />
        </ListItemAvatar> */}
            <ListItemText
              primary={
                <Link
                  className="link"
                  to={`/workspaces/${lmsID}/courses/${courseID}/assignments/${assignment.id}`}
                  // style={{ fontWeight: "bold" }}
                >
                  {`${index + 1}. ${assignment.name}`}
                </Link>
              }
              secondary={
                <Typography
                  sx={{ display: "inline" }}
                  component="span"
                  variant="body2"
                  color="text.primary"
                >
                  {/* {`Assignment ID: ${assignment.id}`} */}
                </Typography>
              }
            />
          </ListItem>
          {index !== AssignmentsList.length - 1 && <Divider />}
        </React.Fragment>
      ))}
    </List>
  );
};

export default AssignmentsList;
