import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { GraderResult } from "./graderModels";

interface GraderState {
  graderResults: { [id: number]: { [id: number]: GraderResult } };
}

interface addGraderResultProps {
  question_id: number;
  student_id: number;
  result: GraderResult;
}

const initialState: GraderState = {
  graderResults: {},
};

export const graderSlice = createSlice({
  name: "grader",

  initialState: initialState,

  reducers: {
    addGraderResult: (state, action: PayloadAction<addGraderResultProps>) => {
      const { question_id, student_id, result } = action.payload;
      if (!(question_id in state.graderResults)) {
        state.graderResults[question_id] = {};
      }

      state.graderResults[question_id][student_id] = result;
    },
  },
});

// Action creators are generated for each case reducer function
export const { addGraderResult } = graderSlice.actions;

export default graderSlice.reducer;
