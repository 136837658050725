import React from 'react';
import styled from 'styled-components';

// Define the styles for the AboutUsPage
const PageContainer = styled.div`
  padding: 20px;
  background: #f9f9f9;
  font-family: 'Arial', sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const TitleContainer = styled.div`
  font-size: 32px;
  font-weight: bold;
  color: #333;
  margin-bottom: 20px;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 1.5px;
`;

const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  max-width: 800px;
`;

const Card = styled.div`
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

const CardTitle = styled.h2`
  font-size: 24px;
  color: #3498db;
  margin-bottom: 10px;
`;

const CardDescription = styled.p`
  font-size: 16px;
  color: #666;
  line-height: 1.6;
`;

const AboutUsPage: React.FC = () => {
  return (
    <PageContainer>
      <TitleContainer>About Us</TitleContainer>
      <CardContainer>
        <Card>
          <CardTitle>Our Mission</CardTitle>
          <CardDescription>
            Our mission is to streamline the grading process for educators and
            enhance the learning experience for students through the use of
            cutting-edge AI technology. We aim to augment learning management
            systems (LMS) with automated grading capabilities, reducing the time
            and effort required to grade student submissions. By doing so, we
            strive to create a more efficient and effective learning environment
            that maximizes the potential for student success.
          </CardDescription>
        </Card>
        <Card>
          <CardTitle>Our Vision</CardTitle>
          <CardDescription>
            Our vision is to revolutionize the education industry by providing
            AI-powered solutions that simplify the submission and grading process.
            We believe that automating routine tasks can free up valuable time for
            educators to focus on what they do best: inspiring and guiding their
            students. By empowering teachers with AI tools, we aim to create a
            more personalized and effective learning experience for students,
            ultimately leading to better outcomes and improved success rates. We
            envision a future where education is more accessible, efficient, and
            engaging for all.
          </CardDescription>
        </Card>
      </CardContainer>
    </PageContainer>
  );
};

export default AboutUsPage;
