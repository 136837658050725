import React from "react";
// import { Avatar, List, Card, Spin, Button } from "antd";
import { useParams, Link } from "react-router-dom";
import StudentItem from "./studentItem";
import { Student } from "../lmsModels";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import { useGetAssignmentGradingStatusQuery } from "../../lmsManagement/lmsManagementApiSlice";

interface StudentsListProps {
  StudentsList: Student[];
}

function stringAvatar(name: string) {
  try {
    return {
      children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
    };
  } catch {
    return {
      children: "",
    };
  }
}

const StudentsList: React.FC<StudentsListProps> = ({ StudentsList }) => {
  const lmsID = useParams().lmsId;
  const courseID = useParams().courseID;
  const assignmentID = useParams().assignmentID;

  const {
    data: statusData,
    error,
    isLoading,
    isFetching,
    isSuccess,
  } = useGetAssignmentGradingStatusQuery({
    lmsId: +lmsID!,
    courseLmsId: +courseID!,
    assignmentLmsId: +assignmentID!,
    questionLmsId: null,
  });

  return (
    <List sx={{ width: "100%", bgcolor: "background.paper" }}>
      {StudentsList.map((student, index) => (
        <React.Fragment key={index}>
          <ListItem
            // alignItems="flex-start"
            sx={{ width: "100%" }}
            // component={Link}
            // to={`/lms/courses/${course.id}`}
          >
            <ListItemAvatar>
              <Avatar {...stringAvatar(student.name)} />
            </ListItemAvatar>
            <ListItemText
              primary={
                <Link
                  className="link"
                  to={`/workspaces/${lmsID}/courses/${courseID}/assignments/${assignmentID}/students/${student.id}`}
                  // style={{ fontWeight: "bold" }}
                >
                  {`${index + 1}. ${student.name}`}
                </Link>
              }
              secondary={
                <Typography
                  sx={{ display: "flex", color: "grey" }}
                  component="span"
                  variant="body2"
                  color="text.primary"
                >
                  {`Questions graded: ${
                    statusData
                      ? statusData.students[`${student.id}`]
                        ? statusData.students[`${student.id}`]
                        : "0"
                      : ""
                  }`}
                </Typography>
              }
            />
          </ListItem>
          {index !== StudentsList.length - 1 && <Divider />}
        </React.Fragment>
      ))}
    </List>
  );
};

export default StudentsList;
