import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import {
  GetUserUsageResponse,
  useGetUserUsageQuery,
} from "../lmsManagement/lmsManagementApiSlice";
import { CircularProgress } from "@mui/material";

const TAX_RATE = 0.0;
const K_TOKENS_PRICE = 0.5;

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#003261",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function ccyFormat(num: number) {
  return `$ ${num.toFixed(2)}`;
}

function priceRow(tokens: number, unit: number) {
  return (tokens / 1000) * unit;
}

function subtotal(items: GetUserUsageResponse[]) {
  const requests = items.reduce(
    (sum, item) => sum + item.num_graded_submissions,
    0
  );
  const tokens = items.reduce((sum, item) => sum + item.sum_tokens, 0);
  const cost = items.reduce(
    (sum, item) => sum + priceRow(item.sum_tokens, K_TOKENS_PRICE),
    0
  );
  return {
    requests: requests,
    tokens: tokens,
    cost: cost,
  };
}

function BillingTable(data: GetUserUsageResponse[]) {
  const requestsSubtotal = subtotal(data).requests;
  const tokensSubtotal = subtotal(data).tokens;
  const invoiceSubtotal = subtotal(data).cost;
  const invoiceTaxes = TAX_RATE * invoiceSubtotal;
  const invoiceTotal = invoiceTaxes + invoiceSubtotal;

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 700 }} size="small" aria-label="spanning table">
        <TableHead>
          <TableRow>
            <StyledTableCell align="center" colSpan={5}>
              Details
            </StyledTableCell>
            <StyledTableCell align="right">Price ($)</StyledTableCell>
          </TableRow>
          <TableRow>
            <TableCell align="right" sx={{ fontWeight: "bold" }}>
              Course
            </TableCell>
            <TableCell align="right" sx={{ fontWeight: "bold" }}>
              Assignment
            </TableCell>
            <TableCell align="right" sx={{ fontWeight: "bold" }}>
              Question
            </TableCell>
            <TableCell align="right" sx={{ fontWeight: "bold" }}>
              Requests
            </TableCell>
            <TableCell align="right" sx={{ fontWeight: "bold" }}>
              Tokens
            </TableCell>
            <TableCell align="right" sx={{ fontWeight: "bold" }}>
              Cost
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row, index) => (
            <StyledTableRow key={index}>
              <TableCell align="right">{row.course_lms_id}</TableCell>
              <TableCell align="right">{row.assignment_lms_id}</TableCell>
              <TableCell align="right">{row.question_lms_id}</TableCell>
              <TableCell align="right">{row.num_graded_submissions}</TableCell>
              <TableCell align="right">
                {row.sum_tokens.toLocaleString("en-US")}
              </TableCell>
              <TableCell align="right">
                {ccyFormat(priceRow(row.sum_tokens, K_TOKENS_PRICE))}
              </TableCell>
            </StyledTableRow>
          ))}
          <StyledTableRow>
            <TableCell colSpan={2} rowSpan={3} />
            <TableCell colSpan={0}>Subtotal</TableCell>
            <TableCell align="right">
              {requestsSubtotal.toLocaleString("en-US")}
            </TableCell>
            <TableCell align="right">
              {tokensSubtotal.toLocaleString("en-US")}
            </TableCell>
            <TableCell align="right">{ccyFormat(invoiceSubtotal)}</TableCell>
          </StyledTableRow>
          <StyledTableRow>
            <TableCell colSpan={2}>Tax</TableCell>
            <TableCell align="right">{`${(TAX_RATE * 100).toFixed(
              0
            )} %`}</TableCell>
            <TableCell align="right">{ccyFormat(invoiceTaxes)}</TableCell>
          </StyledTableRow>
          <StyledTableRow>
            <TableCell colSpan={3}>Total Cost</TableCell>
            <TableCell align="right">{ccyFormat(invoiceTotal)}</TableCell>
          </StyledTableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}

const BillingTableComponent = () => {
  const {
    data: userUsageData,
    refetch,
    error,
    isLoading,
    isFetching,
    isSuccess,
  } = useGetUserUsageQuery();

  useEffect(() => {
    refetch();
  }, []);

  return isLoading ? (
    <CircularProgress />
  ) : isSuccess ? (
    BillingTable(userUsageData)
  ) : (
    <></>
  );
};

export default BillingTableComponent;
