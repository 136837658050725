import { createSelector, createEntityAdapter } from "@reduxjs/toolkit";
import { apiSlice } from "../../api/apiSlice";
import { Course } from "../lmsModels";

export interface GetCoursesRequest {
  lms_type: string;
  base_url: string;
  access_token: string;
}

export const extendedApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getCourses: builder.query<Course[], GetCoursesRequest>({
      query: (data) => {
        return {
          url: "lms_api/get_courses/",
          method: "post",
          body: {
            lms_type: data.lms_type,
            base_url: data.base_url,
            access_token: data.access_token,
          },
        };
      },
      //   transformResponse: (response: Course[]) => {
      //     return response.map((course) => ({
      //       courseId: course.id,
      //       courseName: course.name,
      //       avatar: "",
      //       totalStudents: 10,
      //     }));
      //   },
    }),
  }),
});

export const { useGetCoursesQuery } = extendedApiSlice;
