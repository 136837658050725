import Button from "@mui/material/Button";
import { LoadingButton } from "@mui/lab";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
// import Link from "@mui/material/Link";
import { useParams, Link, useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Alert from "@mui/material/Alert";
import {
  useActivateUserMutation,
  useLoginUserMutation,
  useRefreshTokenMutation,
  useResendActivationMutation,
} from "./authApiSlice";
import { ReactNode, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { FormGroup, FormHelperText } from "@mui/material";
import { setAuthTokens } from "./authSlice";
import CircularProgress from "@mui/material/CircularProgress";

const RegistrationConfirmationPage: React.FC = () => {
  const [resendActivation, resendActivationResult] =
    useResendActivationMutation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const email = data.get("email") as string;
    await resendActivation({ email: email });
  };

  useEffect(() => {}, [resendActivationResult]);

  return (
    <div>
      <Container component="main" maxWidth="xs">
        <Box
          sx={{
            marginTop: 8,
          }}
        >
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1, width: "100%" }}
          >
            <Alert severity="success">
              Activation link sent! Please check your email to complete your
              account activation process
            </Alert>
            <Typography component="h1" variant="h5">
              Resend?
            </Typography>
            <TextField
              error={resendActivationResult.isError}
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
            />
            {resendActivationResult.isSuccess && (
              <Alert severity="success">Activation link sent!</Alert>
            )}
            <LoadingButton
              loading={resendActivationResult.isLoading}
              variant="contained"
              type="submit"
              fullWidth
              sx={{ mt: 3, mb: 2 }}
            >
              Re-send activation link
            </LoadingButton>
          </Box>
        </Box>
      </Container>
    </div>
  );
};

export default RegistrationConfirmationPage;
