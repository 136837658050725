import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import { Link, useNavigate } from "react-router-dom";
import { useGetUserLmsesQuery } from "./lmsManagementApiSlice";
import LmsRemoveDialog from "./lmsRemoveDialog";
import LmsModifyDialog from "./lmsModifyDialog";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { setLms } from "../lms/lmsSlice";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import ListItemText from "@mui/material/ListItemText";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import AddIcon from "@mui/icons-material/Add";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import CircularProgress from "@mui/material/CircularProgress";
import { Box } from "@mui/material";

const LMSList: React.FC = () => {
  const {
    data: userLmsesData,
    refetch,
    error,
    isLoading,
    isFetching,
    isSuccess,
  } = useGetUserLmsesQuery();

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const workingLms = useAppSelector((state) => state.lms.workingLms);

  const addLmsItem = () => {
    navigate("/add-lms");
  };

  const setWorkingLms = (inputObject: {
    lmsId: number;
    userLmsId: number;
    lmsType: string;
    lmsBaseUrl: string;
    lmsAccessToken: string;
  }) => {
    dispatch(
      setLms({
        workingLms: {
          lmsId: inputObject.lmsId,
          userLmsId: inputObject.userLmsId,
          lmsType: inputObject.lmsType,
          lmsBaseUrl: inputObject.lmsBaseUrl,
          lmsAccessToken: inputObject.lmsAccessToken,
        },
      })
    );
  };

  useEffect(() => {
    refetch();
    // console.log(userLmsesData);
  }, []);

  const [openSnackbar, setOpenSnackbar] = React.useState(false);

  const handleClickSnackbar = () => {
    setOpenSnackbar(true);
  };

  const handleCloseSnackbar = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnackbar(false);
  };

  const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref
  ) {
    return <MuiAlert elevation={6} ref={ref} variant="standard" {...props} />;
  });

  return (
    <div>
      <Button
        // style={{ maxWidth: 100 }}
        variant="outlined"
        fullWidth
        onClick={addLmsItem}
        startIcon={<AddIcon />}
        // sx={{ mt: 3, mb: 2 }}
      >
        Add Workspace
      </Button>

      {isLoading ? (
        <CircularProgress />
      ) : (
        <List sx={{ width: "100%", bgcolor: "background.paper" }}>
          {isSuccess &&
            userLmsesData!.map((lms, index) => (
              <React.Fragment key={index}>
                <ListItem
                  // alignItems="flex-start"
                  sx={{ width: "100%" }}
                  secondaryAction={
                    <Box>
                      <LmsModifyDialog
                        userLmsId={lms.id}
                        lmsListrefetch={refetch}
                        handleClickSnackbar={handleClickSnackbar}
                      />

                      <LmsRemoveDialog
                        userLmsId={lms.id}
                        lmsListrefetch={refetch}
                      />
                    </Box>
                  }
                >
                  <ListItemText
                    sx={{ marginRight: "50px" }}
                    primary={
                      <Link
                        className="link"
                        onClick={() => {
                          setWorkingLms({
                            lmsId: lms.lms,
                            userLmsId: lms.id,
                            lmsType: lms.lms_type,
                            lmsBaseUrl: lms.lms_base_url,
                            lmsAccessToken: lms.access_token,
                          });
                        }}
                        to={`/workspaces/${lms.lms}/courses`}
                        // style={{ fontWeight: "bold" }}
                      >
                        {`${index + 1}. ${lms.lms_type.toUpperCase()}: ${
                          lms.lms_institution
                        }`}
                      </Link>
                    }
                    secondary={
                      <Typography
                        sx={{ display: "inline" }}
                        component="span"
                        variant="body2"
                        color="text.primary"
                      ></Typography>
                    }
                  />
                </ListItem>
                {index !== userLmsesData!.length - 1 && <Divider />}
              </React.Fragment>
            ))}
        </List>
      )}
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity="success"
          sx={{ width: "100%" }}
        >
          Settings updated!
        </Alert>
      </Snackbar>
    </div>
  );
};

export default LMSList;
