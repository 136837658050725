import { createSelector, createEntityAdapter } from "@reduxjs/toolkit";
import { apiSlice } from "../../api/apiSlice";
import {
  AssignmentSubmission,
  QuestionSubmission,
  Student,
} from "../lmsModels";

export interface GetAssignmentSubmissionsRequest {
  lms_type: string;
  base_url: string;
  access_token: string;
  courseId: number;
  assignmentId: number;
}

export interface GetAssignmentQuestionSubmissionsRequest {
  lms_type: string;
  base_url: string;
  access_token: string;
  courseId: number;
  assignmentId: number;
  questionId: number;
  page: number;
}

export interface GetAssignmentStudentSubmissionRequest {
  lms_type: string;
  base_url: string;
  access_token: string;
  courseId: number;
  assignmentId: number;
  studentId: number;
}

export const extendedApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAssignmentSubmissions: builder.query<
      AssignmentSubmission[],
      GetAssignmentSubmissionsRequest
    >({
      query: (data) => {
        return {
          url: "lms_api/get_assignment_submissions/",
          method: "post",
          body: {
            lms_type: data.lms_type,
            base_url: data.base_url,
            access_token: data.access_token,
            course_id: data.courseId,
            assignment_id: data.assignmentId,
            page: 1,
            per_page: 30,
          },
        };
      },
    }),

    getAssignmentQuestionSubmissions: builder.query<
      QuestionSubmission[],
      GetAssignmentQuestionSubmissionsRequest
    >({
      query: (data) => {
        // console.log(`getting: ${data.page}`);
        return {
          url: "lms_api/get_assignment_question_submissions/",
          method: "post",
          body: {
            lms_type: data.lms_type,
            base_url: data.base_url,
            access_token: data.access_token,
            course_id: data.courseId,
            assignment_id: data.assignmentId,
            question_id: data.questionId,
            page: data.page,
            per_page: 30,
          },
        };
      },
    }),

    getAssignmentStudentSubmission: builder.query<
      AssignmentSubmission,
      GetAssignmentStudentSubmissionRequest
    >({
      query: (data) => {
        return {
          url: "lms_api/get_assignment_student_submission/",
          method: "post",
          body: {
            lms_type: data.lms_type,
            base_url: data.base_url,
            access_token: data.access_token,
            course_id: data.courseId,
            assignment_id: data.assignmentId,
            student_id: data.studentId,
          },
        };
      },
    }),
  }),
});

export const {
  useGetAssignmentSubmissionsQuery,
  useGetAssignmentQuestionSubmissionsQuery,
  useLazyGetAssignmentQuestionSubmissionsQuery,
  useGetAssignmentStudentSubmissionQuery,
} = extendedApiSlice;
