import Button from "@mui/material/Button";
import { LoadingButton } from "@mui/lab";
import TextField from "@mui/material/TextField";
import { useParams, Link, useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Alert from "@mui/material/Alert";
import { useResetPasswordConfirmMutation } from "./authApiSlice";
import { ReactNode, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { FormGroup, FormHelperText } from "@mui/material";

const ResetPasswordConfirmPage: React.FC = () => {
  const getErrorMessage = (error: any, field: string): [boolean, string] => {
    if (error && error.data && error.data[`${field}`]) {
      return [true, `${error.data[`${field}`]}`];
    }
    return [false, ""];
  };

  const [resetPasswordConfirm, resetPasswordConfirmResult] =
    useResetPasswordConfirmMutation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const uid = useParams().uid;
  const token = useParams().token;

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const new_password = data.get("new_password") as string;
    await resetPasswordConfirm({
      uid: uid!,
      token: token!,
      new_password: new_password,
    });
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography component="h1" variant="h5">
          Reset password
        </Typography>
        <Box
          component="form"
          onSubmit={handleSubmit}
          noValidate
          sx={{ mt: 1, width: "100%" }}
        >
          <TextField
            error={resetPasswordConfirmResult.isError}
            margin="normal"
            required
            fullWidth
            name="new_password"
            label="New Password"
            type="password"
            id="new_password"
            autoComplete="current-password"
            helperText={
              getErrorMessage(resetPasswordConfirmResult.error, "new_password")[1]
            }
          />
          <TextField
            error={resetPasswordConfirmResult.isError}
            margin="normal"
            required
            fullWidth
            name="re_new_password"
            label="Confirm New Password"
            type="password"
            id="re_new_password"
            autoComplete="current-password"
          />
          {resetPasswordConfirmResult.isSuccess && (
            <Alert severity="success">
              Your password has been reset sucessfully!
            </Alert>
          )}
          <LoadingButton
            loading={resetPasswordConfirmResult.isLoading}
            variant="contained"
            type="submit"
            fullWidth
            sx={{ mt: 3, mb: 2 }}
          >
            Reset Password
          </LoadingButton>
        </Box>
      </Box>
    </Container>
  );
};

export default ResetPasswordConfirmPage;
