import { ReactNode, useEffect, useState } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import { LoadingButton } from "@mui/lab";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { Link, useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useRegisterUserMutation } from "./authApiSlice";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

function Copyright(props: any) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" to="/">
        Intelligent Grader
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const theme = createTheme();

const getErrorMessage = (error: any, field: string): [boolean, string] => {
  if (error && error.data && error.data[`${field}`]) {
    return [true, `${error.data[`${field}`]}`];
  }
  return [false, ""];
};

const RegisterPage: React.FC = () => {
  const [registerUser, registerUserResult] = useRegisterUserMutation();
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const firstName = data.get("firstName") as string;
    const lastName = data.get("lastName") as string;
    const email = data.get("email") as string;
    const password1 = data.get("password") as string;
    const password2 = data.get("confirm-password") as string;
    await registerUser({
      firstName: firstName,
      lastName: lastName,
      email: email,
      password1: password1,
      password2: password2,
    });
    console.log({
      firstName: firstName,
      lastName: lastName,
      email: email,
      password1: password1,
      password2: password2,
    });
  };

  useEffect(() => {
    if (registerUserResult.data) {
      if (registerUserResult.isSuccess) {
        navigate("/registration-confirmation");
        console.log(registerUserResult.data);
      }
    }
    console.log(registerUserResult.error);
  }, [registerUserResult]);

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {/* <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <LockOutlinedIcon />
          </Avatar> */}
          <Typography component="h1" variant="h5">
            Sign up
          </Typography>
          <Box
            component="form"
            noValidate
            onSubmit={handleSubmit}
            sx={{ mt: 3 }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  error={
                    getErrorMessage(registerUserResult.error, "first_name")[0]
                  }
                  autoComplete="given-name"
                  name="firstName"
                  required
                  fullWidth
                  id="firstName"
                  label="First Name"
                  autoFocus
                  helperText={
                    getErrorMessage(registerUserResult.error, "first_name")[1]
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  error={
                    getErrorMessage(registerUserResult.error, "last_name")[0]
                  }
                  helperText={
                    getErrorMessage(registerUserResult.error, "last_name")[1]
                  }
                  required
                  fullWidth
                  id="lastName"
                  label="Last Name"
                  name="lastName"
                  autoComplete="family-name"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  error={getErrorMessage(registerUserResult.error, "email")[0]}
                  helperText={
                    getErrorMessage(registerUserResult.error, "email")[1]
                  }
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  error={
                    getErrorMessage(registerUserResult.error, "password")[0]
                  }
                  helperText={
                    getErrorMessage(registerUserResult.error, "password")[1]
                  }
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  // type="password"
                  id="password"
                  autoComplete="new-password"
                  type={showPassword ? "text" : "password"}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  error={
                    getErrorMessage(
                      registerUserResult.error,
                      "non_field_errors"
                    )[0]
                  }
                  helperText={
                    getErrorMessage(
                      registerUserResult.error,
                      "non_field_errors"
                    )[1]
                  }
                  required
                  fullWidth
                  name="confirm-password"
                  label="Confirm Password"
                  // type="password"
                  id="confirm-password"
                  autoComplete="confirm-password"
                  type={showPassword ? "text" : "password"}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              {/* <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox value="allowExtraEmails" color="primary" />
                  }
                  label="I want to receive inspiration, marketing promotions and updates via email."
                />
              </Grid> */}
            </Grid>
            {/* <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            > */}
            <LoadingButton
              loading={registerUserResult.isLoading}
              variant="contained"
              type="submit"
              fullWidth
              sx={{ mt: 3, mb: 2 }}
            >
              Sign Up
            </LoadingButton>
            <Grid container justifyContent="flex-end">
              <Grid item>
                <Link to="/login" className="link">
                  Already have an account? Sign in
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
        {/* <Copyright sx={{ mt: 5 }} /> */}
      </Container>
    </ThemeProvider>
  );
};

export default RegisterPage;
