import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import { Input } from "antd";
import {
  useGetAssignmentSubmissionsQuery,
  useGetAssignmentQuestionSubmissionsQuery,
  useGetAssignmentStudentSubmissionQuery,
} from "../submissionsApiSlice";
import { LoadingOutlined } from "@ant-design/icons";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import StudentQuestionsSubmissionList from "./studentQuestionsSubmissionList";
import {
  Breadcrumbs,
  Card,
  CardHeader,
  Grid,
  Typography,
  Avatar,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import Fade from "@mui/material/Fade";
import HomeIcon from "@mui/icons-material/Home";
import { setFilterByKey } from "../../lmsSlice";

const StudentQuestionsSubmissionsPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const workingLms = useAppSelector((state) => state.lms.workingLms);
  const rubrics = useAppSelector((state) => state.lms.lmsRubrics);
  const lmsID = useParams().lmsId;
  const courseID = useParams().courseID;
  const assignmentID = useParams().assignmentID;
  const studentID = useParams().studentID;

  // const filterByKey = useAppSelector((state) => state.lms.filterByKey);

  const { TextArea } = Input;

  const {
    data: studentAssignmentSubmissionsData,
    error,
    isLoading,
    isFetching,
    isSuccess,
  } = useGetAssignmentStudentSubmissionQuery({
    lms_type: workingLms && workingLms.lmsType!,
    base_url: workingLms && workingLms.lmsBaseUrl!,
    access_token: workingLms && workingLms.lmsAccessToken!,
    courseId: +courseID!,
    assignmentId: +assignmentID!,
    studentId: +studentID!,
  });
  //   console.log(qestionSubmissionsData);

  // const antIcon = <LoadingOutlined style={{ fontSize: 30 }} spin />;

  // console.log(studentAssignmentSubmissionsData);

  useEffect(() => {
    // fetch the assignment rubrics on mount
    dispatch(setFilterByKey("byStudent"));
  }, []);

  return (
    <div>
      <div className="grey-title-container">
        <Breadcrumbs aria-label="breadcrumb">
          <Link
            className="link"
            style={{ display: "flex", alignItems: "center" }}
            color="inherit"
            to="/"
          >
            <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
            Home
          </Link>
          <Link
            className="link"
            style={{ display: "flex", alignItems: "center" }}
            color="inherit"
            to={`/workspaces`}
          >
            Workspaces
          </Link>
          <Link
            className="link"
            style={{ display: "flex", alignItems: "center" }}
            color="inherit"
            to={`/workspaces/${lmsID}/courses`}
          >
            Courses
          </Link>
          <Link
            className="link"
            style={{ display: "flex", alignItems: "center" }}
            color="inherit"
            to={`/workspaces/${lmsID}/courses/${courseID}`}
          >
            Assignments
          </Link>
          <Link
            className="link"
            style={{ display: "flex", alignItems: "center" }}
            color="inherit"
            to={`/workspaces/${lmsID}/courses/${courseID}/assignments/${assignmentID}`}
          >
            Students
          </Link>
          <div>Student</div>
        </Breadcrumbs>
      </div>
      <Grid container>
        {isLoading ? (
          <CircularProgress />
        ) : (
          studentAssignmentSubmissionsData &&
          studentAssignmentSubmissionsData?.student && (
            <Fade in={true}>
              <Card
                raised={true}
                sx={{
                  width: "100%",
                  backgroundColor: "#f5f5f5",
                  border: "1px solid lightgrey",
                  borderRadius: "10px",
                  margin: "15px",
                  marginBottom: "20px",
                }}
              >
                <CardHeader
                  sx={{ backgroundColor: "white" }}
                  avatar={
                    <Avatar
                      src={studentAssignmentSubmissionsData?.student.avatar_url}
                    />
                  }
                  title={
                    <Typography sx={{ fontWeight: "bold" }}>
                      {studentAssignmentSubmissionsData?.student.name}
                    </Typography>
                  }
                  subheader={`ID: ${studentAssignmentSubmissionsData?.student.id}`}
                />
              </Card>
            </Fade>
          )
        )}
      </Grid>
      <div>
        {/* <p className="grey-title-container">{`Student submission`}</p> */}
        <div>
          {isLoading ? (
            <CircularProgress />
          ) : (
            <Fade in={true}>
              <div>
                <StudentQuestionsSubmissionList
                  StudentAssignmentSubmission={
                    studentAssignmentSubmissionsData!
                  }
                />
              </div>
            </Fade>
          )}
        </div>
      </div>
    </div>
  );
};

export default StudentQuestionsSubmissionsPage;
