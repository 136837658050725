import React, { useEffect, useState } from "react";
import { Question, QuestionSubmission, Student } from "../../lmsModels";
import QuestionSubmissionItem from "./questionSubmissionItem";
import InfiniteScroll from "react-infinite-scroll-component";
import { LoadingOutlined } from "@ant-design/icons";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { useParams } from "react-router-dom";
import { useLazyGetAssignmentQuestionSubmissionsQuery } from "../submissionsApiSlice";
import List from "@mui/material/List";
import { Skeleton, Stack, Divider } from "@mui/material";

interface QuestionSubmissionsListProps {
  Question: Question;
  // QuestionSubmissionsList: QuestionSubmission[];
}

const QuestionSubmissionsList: React.FC<QuestionSubmissionsListProps> = ({
  Question,
  // QuestionSubmissionsList,
}) => {
  const antIcon = <LoadingOutlined style={{ fontSize: 30 }} spin />;
  const [loading, setLoading] = useState(false);
  const [canFetchMore, setCanFetchMore] = useState(true);
  const [page, setPage] = useState(1);
  const [data, setData] = useState<{ [id: number]: QuestionSubmission[] }>({});

  const dispatch = useAppDispatch();
  const workingLms = useAppSelector((state) => state.lms.workingLms);
  const courseID = useParams().courseID;
  const assignmentID = useParams().assignmentID;
  const questionID = useParams().questionID;

  const [
    GetAssignmentQuestionSubmissions,
    AssignmentQuestionSubmissionsResults,
  ] = useLazyGetAssignmentQuestionSubmissionsQuery();

  const loadMoreData = async () => {
    if (!canFetchMore) {
      return;
    }
    if (!loading) {
      setLoading(true);
      await GetAssignmentQuestionSubmissions(
        {
          lms_type: workingLms && workingLms.lmsType!,
          base_url: workingLms && workingLms.lmsBaseUrl!,
          access_token: workingLms && workingLms.lmsAccessToken!,
          courseId: +courseID!,
          assignmentId: +assignmentID!,
          questionId: +questionID!,
          page: page,
        },
        true
      );
      setPage(page + 1);
    }

    if (AssignmentQuestionSubmissionsResults.isSuccess) {
      if (AssignmentQuestionSubmissionsResults.data.length === 0) {
        setCanFetchMore(false);
        return;
      }

      // setData([...data, ...AssignmentQuestionSubmissionsResults.data]);
      if (!(page in data)) {
        data[page] = AssignmentQuestionSubmissionsResults.data;
        setData(data);
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    loadMoreData();
  }, [page]);

  const loader = (
    <Stack spacing={1} sx={{ padding: "15px" }}>
      <Skeleton variant="circular" width={40} height={40} />
      <Skeleton variant="rounded" width={"100%"} height={60} />
    </Stack>
  );

  return (
    <InfiniteScroll
      dataLength={Object.values(data).flat().length}
      next={loadMoreData}
      hasMore={canFetchMore}
      loader={loader}
      endMessage={
        <Divider sx={{ padding: "20px" }}>End of submissions</Divider>
      }
      scrollableTarget="scrollableDiv"
    >
      {page === 1 ? (
        <div></div>
      ) : (
        <List sx={{ width: "100%", bgcolor: "background.paper" }}>
          {Object.values(data)
            .flat()
            .map((item, index) => (
              <React.Fragment key={index}>
                <QuestionSubmissionItem
                  question={Question}
                  questionSubmission={item}
                  index={index}
                />
                {index !== Object.values(data).flat().length - 1 && (
                  <Divider sx={{ margin: "10px" }} />
                )}
              </React.Fragment>
            ))}
        </List>
      )}
    </InfiniteScroll>
  );
};

export default QuestionSubmissionsList;
