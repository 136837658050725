import React, { useState } from "react";
import { useParams, Link } from "react-router-dom";
import QuestionsPage from "../questions/questionsPage";
import StudentsPage from "../students/studentsPage";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { setFilterByKey } from "../lmsSlice";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import HomeIcon from "@mui/icons-material/Home";

interface Props {
  children: React.ReactNode;
}

const AssignmentPage: React.FC = () => {
  const lmsID = useParams().lmsId;
  const courseID = useParams().courseID;
  const assignmentID = useParams().assignmentID;
  const dispatch = useAppDispatch();
  const filterByKey = useAppSelector((state) => state.lms.filterByKey);

  // const [value, setValue] = React.useState("1");

  const handleChange = (event: React.SyntheticEvent, value: string) => {
    // setValue(value);
    dispatch(setFilterByKey(value));
  };

  return (
    <div>
      <div className="grey-title-container">
        <Breadcrumbs aria-label="breadcrumb">
          <Link
            className="link"
            style={{ display: "flex", alignItems: "center" }}
            color="inherit"
            to="/"
          >
            <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
            Home
          </Link>
          <Link
            className="link"
            style={{ display: "flex", alignItems: "center" }}
            color="inherit"
            to={`/workspaces`}
          >
            Workspaces
          </Link>
          <Link
            className="link"
            style={{ display: "flex", alignItems: "center" }}
            color="inherit"
            to={`/workspaces/${lmsID}/courses`}
          >
            Courses
          </Link>
          <Link
            className="link"
            style={{ display: "flex", alignItems: "center" }}
            color="inherit"
            to={`/workspaces/${lmsID}/courses/${courseID}`}
          >
            Assignments
          </Link>
          {/* <Link
            className="link"
            style={{ display: "flex", alignItems: "center" }}
            color="inherit"
            to={`/workspaces/${lmsID}/courses/${courseID}/assignments/${assignmentID}`}
          >
            {filterByKey === "byQuestion" ? "Questions" : "Students"}
          </Link> */}
          <div>{filterByKey === "byQuestion" ? "Questions" : "Students"}</div>
        </Breadcrumbs>
      </div>
      <Box sx={{ width: "100%", typography: "body1" }}>
        <TabContext value={filterByKey}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList
              onChange={handleChange}
              aria-label="lab API tabs example"
              variant="fullWidth"
              // orientation="vertical"
            >
              <Tab label="By question" value="byQuestion" />
              <Tab label="By student" value="byStudent" />
            </TabList>
          </Box>
          <TabPanel value="byQuestion" sx={{ margin: 0, padding: 0 }}>
            <QuestionsPage />
          </TabPanel>
          <TabPanel value="byStudent" sx={{ margin: 0, padding: 0 }}>
            <StudentsPage />
          </TabPanel>
        </TabContext>
      </Box>
    </div>
  );
};

export default AssignmentPage;
