import React from "react";
// import { Avatar, List, Card, Spin, Button } from "antd";
import { useParams, Link } from "react-router-dom";
import CourseItem from "./courseItem";
import { Course } from "../lmsModels";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";

interface CoursesListProps {
  coursesList: Course[];
}

const CoursesList: React.FC<CoursesListProps> = ({ coursesList }) => {
  const lmsID = useParams().lmsId;
  return (
    // <List
    //   size="small"
    //   bordered={true}
    //   itemLayout="horizontal"
    //   dataSource={coursesList}
    //   renderItem={(item, index) => <CourseItem course={item} index={index} />}
    // />
    <List sx={{ width: "100%", bgcolor: "background.paper" }}>
      {coursesList.map((course, index) => (
        <React.Fragment key={index}>
          <ListItem
            alignItems="flex-start"
            sx={{ width: "100%" }}
            // component={Link}
            // to={`/lms/courses/${course.id}`}
          >
            {/* <ListItemAvatar>
              <Avatar alt={course.name} src="/static/images/avatar/1.jpg" />
            </ListItemAvatar> */}
            <ListItemText
              primary={
                <Link
                  className="link"
                  to={`/workspaces/${lmsID}/courses/${course.id}`}
                  // style={{ fontWeight: "bold" }}
                >
                  {course.name}
                </Link>
              }
              secondary={
                <Typography
                  sx={{ display: "inline", color: "grey" }}
                  component="span"
                  variant="body2"
                  color="text.primary"
                >
                  {`Total number of students: ${course.num_students}`}
                </Typography>
              }
            />
          </ListItem>
          {index !== coursesList.length - 1 && <Divider />}
        </React.Fragment>
      ))}
    </List>
  );
};

export default CoursesList;
