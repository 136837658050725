import React, { useState } from "react";
import LinearProgress, {
  LinearProgressProps,
} from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {
  useGetGradingQuestionSubmissionsJobQuery,
  useGetTaskProgressQuery,
  useLazyGetGradingQuestionSubmissionsJobQuery,
} from "./graderApiSlice";
import StopIcon from "@mui/icons-material/Stop";
import { IconButton } from "@mui/material";
import Button from "@mui/material/Button";
import StopJobDialog from "./stopJobDialog";

function LinearProgressWithLabel(
  props: LinearProgressProps & { value: number }
) {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

interface GradingJobProgressProps {
  lms_id: number;
  course_lms_id: number;
  assignment_lms_id: number;
  question_lms_id: number;
  task_id: string;
  job_id: number;
  // refetch: () => void;
}

const GradingJobProgress: React.FC<GradingJobProgressProps> = ({
  lms_id,
  course_lms_id,
  assignment_lms_id,
  question_lms_id,
  task_id,
  job_id,
  // refetch,
}) => {
  const [progress, setProgress] = React.useState(0);

  const [
    GetGradingQuestionSubmissionsJob,
    GetGradingQuestionSubmissionsJobResult,
  ] = useLazyGetGradingQuestionSubmissionsJobQuery();

  // const { data, refetch, error, isLoading, isFetching, isSuccess, isError } =
  //   useGetGradingQuestionSubmissionsJobQuery({
  //     lms_id: lms_id,
  //     course_lms_id: course_lms_id,
  //     assignment_lms_id: assignment_lms_id,
  //     question_lms_id: question_lms_id,
  //   });

  const {
    data: taskProgressData,
    refetch: taskProgressRefresh,
    error: taskProgressError,
    isLoading: taskProgressIsLoading,
    isFetching: taskProgressIsFetching,
    isSuccess: taskProgressIsSuccess,
    isError: taskProgressIsError,
  } = useGetTaskProgressQuery({
    taskId: task_id,
  });

  const [shouldStop, setShouldStop] = useState(false);

  React.useEffect(() => {
    if (
      !shouldStop
      // taskProgressData?.state === "PENDING" ||
      // taskProgressData?.state === "PROGRESS"
    ) {
      const timer = setInterval(() => {
        // console.log("progress updated", taskProgressData?.progress.percent!);
        setProgress(taskProgressData?.progress.percent!);

        taskProgressRefresh();
        if (
          taskProgressData?.state !== "PENDING" &&
          taskProgressData?.state !== "PROGRESS"
        ) {
          // console.log("set shouldstop to true");
          // setShouldStop(true);
          window.location.reload();
        }

        // console.log(data && data.progress);
        // setProgress(data!.progress * 100);
      }, 1000);
      return () => {
        clearInterval(timer);
      };
    }
  }, [taskProgressData]);

  return (
    <Box sx={{ width: "100%" }}>
      <p style={{ margin: "5px" }}>
        {taskProgressData?.progress.description
          ? taskProgressData?.progress.description
          : "Please wait ..."}
      </p>
      <LinearProgressWithLabel
        sx={{ height: "10px", borderRadius: "3px" }}
        variant="determinate"
        value={progress}
      />
      <StopJobDialog job_id={job_id} />
    </Box>
  );
};

export default GradingJobProgress;
